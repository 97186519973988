/* eslint-disable react/jsx-no-useless-fragment */
import { ReactElement, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { selectIsSignUpComplete, selectRootLoading, selectUserData } from 'common/store/selectors';
import { SignUpContent } from './components';
import { EmailCheck } from './components/email-check';
import { SignUpComplete } from './components/sign-up-complete';
import { IAsinData } from './components/payment-information/payment-information';

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  address: string;
  email: string;
  phone: string;
  password: string;
  domain: string;
  isAffiliateContent: boolean;
  isBrand: boolean;
  sign: File;
  orderList: string;
  payment: string;
  cardName: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
  arContactFirstName: string;
  arContactLastName: string;
  arContactEmail: string;
  acctContactFirstName: string;
  acctContactLastName: string;
  acctContactEmail: string;
  socials?: { facebook: string; instagram: string; tiktok: string; twitter: string; youtube: string };
  facebook: string;
  instagram: string;
  tiktok: string;
  twitter: string;
  youtube: string;
  confirmPassword: string;
  summaryService: boolean;
  termsAndConditionals: boolean;
  orders: IAsinData;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is a required field'),
  lastName: Yup.string().required('Last name is a required field'),
  companyName: Yup.string().required('Company name is a required field'),
  address: Yup.string().required('Address is a required field'),
  email: Yup.string().required('Email is a required field'),
  phone: Yup.string().required('Phone is a required field'),
  domain: Yup.string()
    .required('Domain is a required field')
    .matches(/amazon.com\/stores\/./gm, 'Store URL should be like amazon.com/stores/test'),
  password: Yup.string()
    .required('Password is a required field')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/,
      'Password must be at least 6 characters, one letter and one number'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is a required field')
    .matches(/^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/, 'Password must be at least 6 characters, one letter and one number')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
  isAffiliateContent: Yup.string().required(),
  orders: Yup.array().of(
    Yup.object({
      asin: Yup.string().min(3).required('ASIN is required field'),
      category: Yup.string().required(),
      budget: Yup.number().min(500).max(5000).required('Budget should be in range'),
    })
  ),
});

export function SignUp(): ReactElement {
  const [newUserCheckPassed, setNewUserCheckPassed] = useState(false);

  const user = useSelector(selectUserData);
  const rootLoading = useSelector(selectRootLoading);
  const isSignUpComplete = useSelector(selectIsSignUpComplete);

  const getInitialValues: SignUpFormValues = {
    firstName: user?.first_name ?? '',
    lastName: user?.last_name ?? '',
    address: user?.address ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    password: '',
    confirmPassword: '',
    companyName: user?.company_name ?? '',
    domain: user?.domain ?? '',
    isAffiliateContent: user?.is_affiliate_content ?? false,
    isBrand: user?.is_brand ?? true,
    sign: null,
    orderList: '',
    payment: '',
    cardName: '',
    cardNumber: '',
    expiration: '',
    cvv: '',
    arContactFirstName: user?.ar_contact_first_name ?? '',
    arContactLastName: user?.ar_contact_last_name ?? '',
    arContactEmail: user?.ar_contact_email ?? '',
    acctContactFirstName: user?.acct_contact_first_name ?? '',
    acctContactLastName: user?.acct_contact_last_name ?? '',
    acctContactEmail: user?.acct_contact_email ?? '',
    facebook: user?.socials?.facebook ?? '',
    instagram: user?.socials?.instagram ?? '',
    tiktok: user?.socials?.tiktok ?? '',
    twitter: user?.socials?.twitter ?? '',
    youtube: user?.socials?.youtube ?? '',
    summaryService: false,
    termsAndConditionals: false,
    orders: null,
  };

  const handleFormSubmit = () => {
    //
  };

  return isSignUpComplete ? (
    <SignUpComplete />
  ) : (
    <>
      {newUserCheckPassed || user?.is_verified ? (
        <>
          {!rootLoading && (
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={getInitialValues}
              validationSchema={validationSchema}
              validateOnChange
              validateOnMount
            >
              <SignUpContent />
            </Formik>
          )}
        </>
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={getInitialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnMount
        >
          <EmailCheck setNewUserCheckPassed={setNewUserCheckPassed} />
        </Formik>
      )}
    </>
  );
}
