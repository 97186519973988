import { UserData } from 'common/interfaces/root-store';
import { State } from './reducers';

export const selectRootLanguage = (state: State): string => state.root.lang;

export const selectRootLoading = (state: State): boolean => state.root.loading;

export const selectUserData = (state: State): UserData => state.root.user_info;

export const selectLoginUrl = (state: State): string => state.root.successUrl;

export const selectIsSignUpComplete = (state: State): boolean => state.root.isSignUpComplete;
