import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { Box } from '@mui/material';
import { quickStartActions } from 'modules/quick-start/store/actions';
import { QUICK_START_STEP } from 'modules/quick-start/constants';
import { getStyles } from './styles';
import { QuickStartTitle } from '../quick-start-title';
import { LoginLinkArea } from '../login-link-area';

export function QuickStartSelect(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const handleSignUp = () => {
    dispatch(quickStartActions.setQuickStartStep(QUICK_START_STEP.SIGNUP));
  };

  return (
    <Box sx={styles.contentBar}>
      <Box sx={styles.content}>
        <QuickStartTitle />
        <Box sx={styles.buttons}>
          <Box sx={styles.connect} onClick={handleSignUp}>
            <Trans i18nKey="quick-start.sign-up">Sign Up</Trans>
          </Box>
        </Box>
        <LoginLinkArea />
      </Box>
    </Box>
  );
}
