import { Action } from 'common/interfaces/root-store';
import { teamInviteActionTypes as T } from '../actions';

const initState: TeamInviteModule.TeamInviteStore = {
  loading: true,
  exist: false,
  error: null,
};

export default (
  state: TeamInviteModule.TeamInviteStore = initState,
  { type, payload }: Action
): TeamInviteModule.TeamInviteStore => {
  switch (type) {
    case T.VERIFY_INVITATION_CODE_SUCCESS:
      return { ...state, loading: false, exist: payload };
    case T.VERIFY_INVITATION_CODE_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
