import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { verifyInviteLink } from 'common/store/actions';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { useQuery } from 'common/hooks';
import { signUpActions } from 'modules/sign-up/store/actions';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  children: ReactElement;
}

export function AuthProvider({ children }: Props): JSX.Element {
  const dispatch = useDispatch();
  const code = useQuery('code');
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingCode, setIsLoadingCode] = useState(true);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);

  const loadingWrapper = () => {
    return (
      <Wrapper>
        <CircularProgress color="primary" />
      </Wrapper>
    );
  };

  useEffect(() => {
    if (location.pathname !== '/invite-user/' && location.pathname !== '/invite-admin/') {
      if (code) {
        dispatch(
          verifyInviteLink(code, () => {
            setIsLoadingCode(false);
          })
        );
      } else {
        setIsLoadingCode(false);
      }
    } else {
      setIsLoadingCode(false);
      setIsLoadingInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (location.pathname === '/invite-user/' || location.pathname !== '/invite-admin/') {
      setIsLoadingCode(false);
      setIsLoadingInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!code) {
      if (!isLoadingCode) {
        const hash = sessionStorage.getItem('hash');

        if (hash) {
          dispatch(
            signUpActions.getUserInfo(
              hash,
              () => setIsLoadingInfo(false),
              () => sessionStorage.removeItem('hash')
            )
          );
        } else {
          setIsLoadingInfo(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCode, code]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{!isLoadingCode && !isLoadingInfo ? <>{children}</> : <>{loadingWrapper()}</>}</>;
}
