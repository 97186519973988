import { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as FirstItem } from 'common/svg/1.svg';
import { ReactComponent as SecondItem } from 'common/svg/2.svg';

const FirstImageWrapper = styled.div`
  position: absolute;
  bottom: 56px;
  left: 81px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 1350px) {
    display: none;
  }
`;

const SecondImageWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 81px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 1350px) {
    display: none;
  }
`;

export function BackgroundImages(): ReactElement {
  return (
    <>
      <FirstImageWrapper>
        <FirstItem />
      </FirstImageWrapper>
      <SecondImageWrapper>
        <SecondItem />
      </SecondImageWrapper>
    </>
  );
}
