import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from '../../common/constants/theme';

const getStyles = (): Styles => ({
  container: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  headerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    transition: 'padding-left 0.225s ease-in-out',
  },
  contentContainer: {
    minHeight: 'calc(100vh)',
    boxSizing: 'border-box',
  },
});

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props): ReactElement {
  const location = useLocation();

  const styles = getStyles();

  const topAnchorRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, location.pathname]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContentContainer}>
        <Box sx={styles.contentContainer}>{children}</Box>
      </Box>
    </Box>
  );
}
