const MODULE_NAME = 'google-map';

export const GET_PLACE_NAME = `[${MODULE_NAME}] GET_PLACE_NAME`;
export const PUT_PLACE_NAME = `[${MODULE_NAME}] PUT_PLACE_NAME`;
export const PUT_PLACE_NAME_ERROR = `[${MODULE_NAME}] PUT_PLACE_NAME_ERROR`;

export const GET_PLACE_COORDINATES = `[${MODULE_NAME}] GET_PLACE_COORDINATES`;
export const PUT_PLACE_COORDINATES_ERROR = `[${MODULE_NAME}] PUT_PLACE_COORDINATES_ERROR`;

export const PUT_LAT_LNG = `[${MODULE_NAME}] PUT_LAT_LNG`;
