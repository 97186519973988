/* eslint-disable no-console */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactElement, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import './stripe-form.css';

interface Props {
  isGeneratingToken: boolean;
  setIsGeneratingToken: (value: boolean) => void;
  createWallet: (value: string) => void;
}

export function StripeForm({ isGeneratingToken, createWallet, setIsGeneratingToken }: Props): ReactElement {
  const useOptions = () => {
    const options = useMemo(
      () => ({
        hidePostalCode: true,
        style: {
          base: {
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
            m: '10px 0',
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }),
      []
    );

    return options;
  };

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      toast.error(result.error.message);
      return;
    }
    createWallet(result.token.id);
  };

  useEffect(() => {
    if (isGeneratingToken) {
      handleSubmit();
      setIsGeneratingToken(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingToken]);

  return (
    <form>
      <CardElement options={options} />
    </form>
  );
}
