import { forwardRef, ReactElement } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.css';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

interface Props {
  phone: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const MuiTextFieldWithRef = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <TextField inputRef={ref} {...props} />
));

export function PhoneInputCustom({ phone, disabled, onChange }: Props): ReactElement {
  return (
    <PhoneInput
      international
      style={{ width: '100%' }}
      defaultCountry="US"
      value={phone}
      onChange={onChange}
      name="phone"
      limitMaxLength
      disabled={disabled}
      inputComponent={MuiTextFieldWithRef}
    />
  );
}
