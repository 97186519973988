export const primary = '#4288F0';
export const primaryLight = '#E7F2F7';
export const background = '#F4F9FB';
export const paper = '#fff';
export const text = '#060F14';

// Ignite
export const orange = '#F6922F';

export const stroke = '#343737';

export const input = '#202323';

export const white = '#FFFFFF';

export const menu = '#161919';

export const green4 = '#32766C';

export const igniteText = '#909798';
