import { Box, Typography, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import { PhoneInputCustom } from 'common/ui/phone-input/phone-input';
import { useFormikContext } from 'formik';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { ReactElement } from 'react';

const styles: Styles = {
  fieldRowContainer: { display: 'flex', flexDirection: 'row' },
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  field: { width: '100%' },
};

export function BusinessInformation(): ReactElement {
  const { values, setFieldValue } = useFormikContext<SignUpFormValues>();

  const addDashes = (value: string) => {
    if (value && value !== undefined) {
      return `${value.slice(0, 2)} ${value.slice(2, 5)} ${value.slice(5, 8)} ${value.slice(8, 16)}`;
    }
    return false;
  };

  const handleChangePhone = (value: string) => {
    const newValue = addDashes(value);
    setFieldValue('phone', newValue);
  };

  return (
    <>
      <Box sx={styles.fieldRowContainer}>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>First Name</Typography>
          <Box sx={styles.inputContainer}>
            <Field component={TextField} name="firstName" placeholder="John" sx={styles.field} />
          </Box>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Last Name</Typography>
          <Box sx={styles.inputContainer}>
            <Field component={TextField} name="lastName" placeholder="Smith" sx={styles.field} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.fieldRowContainer}>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Entered email:</Typography>
          <Typography>{values.email}</Typography>
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Phone number</Typography>
        <Box sx={styles.inputContainer}>
          <PhoneInputCustom phone={values.phone} onChange={handleChangePhone} />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Account password</Typography>
        <Box sx={styles.inputContainer}>
          <Field component={TextField} type="password" name="password" placeholder="Password" sx={styles.field} />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Confirm password</Typography>
        <Box sx={styles.inputContainer}>
          <Field
            component={TextField}
            type="password"
            name="confirmPassword"
            placeholder="Password"
            sx={styles.field}
          />
        </Box>
      </Box>
    </>
  );
}
