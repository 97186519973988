/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { loadGoogleScript } from 'common/utils/load-google-script';
import { TextField, TextFieldClasses, SxProps } from '@mui/material';

export interface LatLong {
  lat: number;
  lng: number;
}

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  classes?: Partial<TextFieldClasses>;
  error?: string;
  disabled?: boolean;
  onFocus?: () => void;
  id?: string;
  placeholder?: string;
  sx?: SxProps;
}

function AddressInput({
  value,
  onChange,
  classes,
  error,
  disabled,
  onFocus,
  id,
  placeholder,
  sx,
}: Props): ReactElement {
  const [isTouched, setIsTouched] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const autocomplete = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isScriptLoaded = useRef<boolean>(false);

  const handleInputFocus = () => {
    setTimeout(() => {
      const popover = document.getElementsByClassName('pac-container')[0] as HTMLElement;

      if (popover) {
        popover.style.zIndex = '99999';
      }
    }, 500);

    if (isTouched) return;

    setIsTouched(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  useLayoutEffect(() => {
    loadGoogleScript(() => {
      isScriptLoaded.current = true;
    });
  }, []);

  useEffect(() => {
    const shouldEffect = inputRef.current && (window as any).google && isScriptLoaded;

    if (!shouldEffect) return;

    // eslint-disable-next-line no-new
    autocomplete.current = new (window as any).google.maps.places.Autocomplete(inputRef.current, {
      fields: ['formatted_address', 'geometry', 'name'],
    });

    const autocompleteMounted = autocomplete && autocomplete.current;

    if (!autocompleteMounted) return;

    const handlePlaceChange = () => {
      const place = autocomplete.current.getPlace();
      onChange(place.formatted_address);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = autocomplete.current.addListener('place_changed', handlePlaceChange);

    // eslint-disable-next-line consistent-return
    return () => {
      listener.remove();
      document.querySelector('.pac-container')?.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(window as any).google, isScriptLoaded]);

  return (
    <TextField
      ref={inputRef}
      onFocus={handleInputFocus}
      onChange={handleInputChange}
      placeholder={placeholder ?? ''}
      value={value}
      classes={classes}
      error={isTouched && Boolean(error)}
      helperText={isTouched && error}
      disabled={disabled}
      id={id}
      sx={sx}
    />
  );
}

export default AddressInput;
