import { Styles } from 'common/types';
import styled from 'styled-components';
import { theme } from '../../../../common/constants/theme';

interface StylesProps {
  isTablet: boolean;
  isFirstStep: boolean;
}

export const getSignUpStyles = ({ isTablet, isFirstStep }: StylesProps): Styles => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  cardContent: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  wrapper: {
    paddingTop: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperForm: { maxWidth: '588px', width: '100%' },
  header: { display: 'flex', justifyContent: 'space-between' },
  status: {
    backgroundColor: '#FFAE3C',
    borderRadius: '10px',
    height: '24px',
    minWidth: '101px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
  },
  progressBar: {
    mt: '35px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    borderRadius: '30px',
    height: '4px',
  },
  content: {
    mt: '40px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    minHeight: '700px',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    zIndex: 9999,
  },
  stepTitle: {
    color: theme.palette.primary.main,
  },
  title: {
    mt: '12px',
    fontWeight: 600,
  },
  stepName: {
    fontWeight: 600,
    ml: '7px',
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginWrapper: {
    mt: 1.7,
    display: 'flex',
    flexDirection: 'row',
  },
  loginLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    ml: 0.5,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  bttnWrapper: {
    display: 'flex',
    justifyContent: isFirstStep ? 'flex-end' : 'space-between',
    flexDirection: isTablet ? 'column' : 'row',
    mt: 3,
  },
  saveBttn: {
    borderRadius: '12px',
    minWidth: '116px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    mt: isTablet ? 1 : 0,
  },
  nextStepBttn: {
    borderRadius: '12px',
    minWidth: '116px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    mt: isTablet ? 1 : 0,
  },
  progressBarWrapper: {
    width: '100%',
    padding: '0 10px',
    boxSizing: 'border-box',
  },
  buttonPanelContent: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backStep: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  accountTitle: {
    mt: '12px',
    mb: '6px',
    fontWeight: 600,
  },
  accountExistContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export const ButtonWrappers = styled.div`
  background-color: ${theme.palette.background.default};
  height: 104px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -2px 5px rgb(0 0 0 / 5%);
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
