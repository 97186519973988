import { Action } from 'common/interfaces/root-store';
import { signUpActionTypes as T } from '../actions';

const initState: SignUpModule.SignUpStore = {
  loading: true,
  error: null,
  data: null,
  user_id: null,
  url_sign: null,
  loading_url: true,
  user_info: {
    first_name: '',
    last_name: '',
    email: '',
  },
  cards: null,
};

export default (state: SignUpModule.SignUpStore = initState, { type, payload }: Action): SignUpModule.SignUpStore => {
  switch (type) {
    case T.POST_ASIN_INFORMATION:
      return { ...state, loading: true, data: payload };
    case T.ADD_USER_ID:
      return { ...state, user_id: payload };
    case T.ADD_SIGN_URL:
      return { ...state, url_sign: payload, loading_url: false };
    case T.ADD_CARD_DATA:
      return { ...state, cards: payload };
    case T.SET_USER_INFO:
      return { ...state, user_info: payload };
    default:
      return state;
  }
};
