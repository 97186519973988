import { Styles } from 'common/types';

export const getStyles = (): Styles => ({
  title1: {
    color: '#214254',
    fontSize: '41px',
    fontWeight: 500,
    lineHeight: '41px',
    fontFamily: 'Roboto',
  },
  title2: {
    color: '#060F14',
    fontSize: '41px',
    fontWeight: 500,
    lineHeight: '41px',
    fontFamily: 'Roboto',
  },
  desc: {
    fontFamily: 'Roboto',
    marginTop: '17px',
    color: '#214254',
    fontSize: '16px',
    lineHeight: '30px',
  },
});
