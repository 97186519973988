export const enTranslations = {
  'quick-start.title-1': 'Anyone can run ads.',
  'quick-start.title-2': 'Make yours unbeatable.',
  'quick-start.desc':
    'To get started just enter your information, and we will get your Attribution campaigns up and running!',
  'quick-start.sign-up': 'Sign Up',
  'quick-start.login-text': 'Already have an account?',
  'quick-start.login-bttn': 'Login',
  'quick-start.our-solution': 'Our Solution',
  'quick-start.competitor-direct': 'Competitor Direct',
  'quick-start.product-sales': 'Product Sales',
  'quick-start.continue': 'Continue',
  'quick-start.email': 'Email',
  'quick-start.password': 'Password',
  'quick-start.confirm-password': 'Confirm Password',
  'quick-start.brand-name': 'Brand Name',
  'quick-start.storefront': 'Storefront URL',
  'quick-start.connect': 'Connect',
  'quick-start.password-validation-1':
    'Password must be at least 8 characters, one uppercase and lowercase letter and one number',
  'quick-start.password-validation-2': 'Passwords must match',
};
