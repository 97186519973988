import { background } from 'common/constants/colors';
import { Styles } from 'common/types';

export const getStyles = (): Styles => ({
  contentBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    padding: '70px 110px 45px 160px',
    boxSizing: 'border-box',
    backgroundColor: `${background}`,
    '@media (max-width:1270px)': {
      padding: '70px 70px 70px 120px',
    },
    '@media (max-width:1140px)': {
      width: '70%',
    },
    '@media (max-width:920px)': {
      width: '90%',
    },
  },
  rightWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBlock: {
    width: '506px',
    height: '421px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subBlocksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '17px',
  },
  subBlock: {},
  subBlockTitle: {
    textAlign: 'center',
    color: '#060F14',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '20px',
    width: '130px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vsTitle: {
    textAlign: 'center',
    color: '#7B45DD',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    width: '100%',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  salesProgressBar: {
    marginTop: '20px',
    backgroundColor: '#a6a2d4',
    borderRadius: '3px',
    width: '100%',
    height: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressTitle: {
    marginTop: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    boxSizing: 'border-box',
    color: '#214254',
  },
  subBlockValue: {
    color: '#060F14',
    fontSize: '10px',
    fontFamily: 'Roboto',
  },
  rightBlockAdditional: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    height: '102px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 20px rgba(21, 30, 34, 0.1)',
    transform: 'translateY(50%) translateX(80%)',
    padding: '24px 34px',
    boxSizing: 'border-box',
  },
  sum: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  skeleton: {
    backgroundColor: '#DDE8ED',
    width: '36px',
    height: '7px',
    borderRadius: '10px',
  },
  sumValue: {
    color: '#62D294',
    marginLeft: '4px',
    fontFamily: 'Roboto',
  },
  sumPerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
  },
  sumValWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '20px',
  },
  bottomIconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  lightningWrapper: {
    transform: 'translateY(-25%) translateX(-205%)',
  },
  lightningShadowWrapper: {
    transform: 'translateY(10%) translateX(-380%)',
  },
  mBox: {
    borderRadius: '6px',
    backgroundColor: '#0D0717',
    minWidth: '36px',
    height: '36px',
    transform: 'translateY(150%) translateX(300%)',
  },
  lBox: {
    border: '3px dashed #ffffff34',
    minWidth: '150px',
    height: '150px',
    borderRadius: '12px',
    transform: 'translateY(-70%) translateX(110%)',
  },
  topIconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  sBox: {
    borderRadius: '3px',
    backgroundColor: '#0D0717',
    minWidth: '15px',
    height: '15px',
    transform: 'translateY(800%) translateX(-1400%)',
  },
  wBox: {
    borderRadius: '6px',
    backgroundColor: '#ffffff37',
    minWidth: '80px',
    height: '80px',
    transform: 'translateY(180%) translateX(-290%)',
  },
});
