import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root-store';
import { quickStartApi } from 'modules/quick-start/service/quick-start-service';
import { toast } from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { quickStartActions, quickStartActionTypes } from '../actions';

function* postQuickStartInfo({ payload }: Action) {
  try {
    const { data } = yield call(quickStartApi.postQuickStartBrand, payload);
    if (data.redirectUrl) {
      window.open(data.redirectUrl);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

function* postQuickLoginForm({ payload }: Action) {
  try {
    const { data } = yield call(quickStartApi.postQuickStartLogin, payload);
    yield put(quickStartActions.setQuickUserData(data.user_id));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

function* postQuickSignUpForm({ payload }: Action) {
  try {
    const { data } = yield call(quickStartApi.postQuickStartSignUp, payload);
    yield put(quickStartActions.setQuickUserData(data.user_id));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export function* quickStartSaga(): SagaIterator {
  yield all([
    takeLatest(quickStartActionTypes.POST_QUICK_START_FORM, postQuickStartInfo),
    takeLatest(quickStartActionTypes.POST_QUICK_LOGIN_FORM, postQuickLoginForm),
    takeLatest(quickStartActionTypes.POST_QUICK_SIGNUP_FORM, postQuickSignUpForm),
  ]);
}
