import { Overrides } from 'common/types';
import styled from 'styled-components';
import { TextField } from '@mui/material';

const CustomField = styled(TextField)`
  height: 58px;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #1b2234;
  resize: horizontal;

  :active {
    width: auto;
  }

  ::placeholder {
    color: rgba(27, 34, 52, 0.35);
  }

  ${({ overrides }: Overrides) => overrides};
`;

export default CustomField;
