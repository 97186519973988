import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { BackgroundImages } from 'common/ui/background-images';
import { useSelector } from 'react-redux';
import { selectInviteError } from 'modules/team-invite/selectors';

const getStyles = (): Styles => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  cardContent: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  accountTitle: {
    mt: '12px',
    mb: '6px',
    fontWeight: 600,
  },
  accountExistContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

enum ErrorTypes {
  userExist = 'User already exist on exist client',
  inviteLinkExpired = 'Invitation expired',
  inviteCanceled = 'Invitation canceled',
}

export function ErrorPage(): ReactElement {
  const styles = getStyles();
  const inviteError = useSelector(selectInviteError);

  let errorDescription = '';

  switch (inviteError.error.response.data.message) {
    case ErrorTypes.userExist:
      errorDescription = 'The invite link that you used has been used previously';
      break;
    case ErrorTypes.inviteLinkExpired:
      errorDescription = 'The invite link you followed has expired';
      break;
    case ErrorTypes.inviteCanceled:
      errorDescription = 'Invitation canceled';
      break;
    default:
      break;
  }

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardContent}>
        <Box sx={styles.accountExistContainer}>
          <Typography variant="h5" sx={styles.accountTitle}>
            Something went wrong
          </Typography>
          <Typography>{errorDescription}</Typography>
        </Box>
      </Box>
      <BackgroundImages />
    </Box>
  );
}
