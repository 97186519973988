import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import store from 'common/store';
import { App } from 'root';
import { ThemeProvider } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from 'root/auth/auth';
import './config/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <>
            <Toaster />
            <App />
          </>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </Provider>
);
