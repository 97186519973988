import { IAsinData } from 'modules/sign-up/components/payment-information/payment-information';
import { signUpActionTypes as T } from './action-types';

const postAsinInformation = (
  payload: { data: SignUpModule.CompanyInformation; hash: string },
  onSuccess: () => void,
  onError: (value: string) => void
) => ({
  type: T.POST_ASIN_INFORMATION,
  payload,
  meta: { onSuccess, onError },
});

const postBusinessInformation = (
  payload: { data: SignUpModule.BusinessInformation; hash: string },
  onSuccess: () => void,
  onError: (value: string) => void
) => ({
  type: T.POST_BUSINESS_INFORMATION,
  payload,
  meta: { onSuccess, onError },
});

const postAdditionalInformation = (
  payload: { data: SignUpModule.AdditionalInformation; hash: string },
  onSuccess: () => void,
  onError: (value: string) => void
) => ({
  type: T.POST_ADDITIONAL_INFORMATION,
  payload,
  meta: { onSuccess, onError },
});

const postContractTerms = (
  payload: {
    summary_service: boolean;
    terms_and_conditionals: boolean;
    hash: string;
  },
  onSuccess: () => void,
  onError: (value: string) => void
) => ({
  type: T.POST_CONTRACT_TERMS,
  payload,
  meta: { onSuccess, onError },
});

const getWallet = (payload: { hash: string }, onComplete: (value: boolean) => void) => ({
  payload,
  type: T.GET_WALLET,
  meta: { onComplete },
});

const createWallet = (payload: { hash: string; card_token_id: string }, onEnd: (value: boolean) => void) => ({
  payload,
  type: T.CREATE_WALLET,
  meta: { onEnd },
});

const postPaymentInformation = (
  payload: { data: SignUpModule.PaymentInformation; hash: string },
  onSuccess: () => void,
  onError: (value: string) => void
) => ({
  type: T.POST_PAYMENT_INFORMATION,
  payload,
  meta: { onSuccess, onError },
});

const addUserId = (payload: number) => ({
  type: T.ADD_USER_ID,
  payload,
});

const addSignUrl = (payload: string) => ({
  type: T.ADD_SIGN_URL,
  payload,
});

const addCardData = (payload: { cards: [] }) => ({
  type: T.ADD_CARD_DATA,
  payload,
});

const getUserInfo = (payload: string, onGenerated: () => void, onError: () => void) => ({
  type: T.GET_USER_INFO,
  payload,
  meta: { onGenerated, onError },
});

const setUserInfo = (payload: { first_name: string; last_name: string }) => ({
  type: T.SET_USER_INFO,
  payload,
});

const completeSingUp = (
  payload: {
    hash: string;
    data?: {
      orders: IAsinData;
    };
  },
  onError: (value: string) => void
) => ({
  payload,
  type: T.COMPLETE_SIGN_UP,
  meta: { onError },
});

export const signUpActions = {
  postAsinInformation,
  postBusinessInformation,
  postAdditionalInformation,
  postContractTerms,
  postPaymentInformation,
  addUserId,
  addSignUrl,
  addCardData,
  getUserInfo,
  setUserInfo,
  getWallet,
  createWallet,
  completeSingUp,
};
