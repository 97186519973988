import { ReactElement, useLayoutEffect, memo, useState } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Styles } from 'common/types';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import RelativeList from 'common/ui/relative-list';
import styled from 'styled-components';
import { Modal } from 'common/ui/modal';
import './document-preview.css';

const styles: Styles = {
  fieldRowContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1, mt: 1 },
  bttnWrapper: { display: 'flex', justifyContent: 'center', width: '100%' },
  agreement: {
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  agreeContainer: {
    border: '1px dashed #C2DEEB',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    p: '10px',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    color: 'white',
    right: '24px',
    top: '24px',
  },
};

const Content = styled.div`
  width: 100%;
  height: 400px;
`;

const StyledDocViewer = styled(DocViewer)`
  height: 100%;
`;

interface Props {
  open: boolean;
  title: string;
  link: string;
  onClose: () => void;
}

function DocumentPreview({ open, title, link, onClose }: Props): ReactElement {
  const [wrapperRef, setWrapperRef] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    // remove header from dom, because removing header via component config crash pdf renderer
    setTimeout(() => {
      document.querySelector('#header-bar')?.remove();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handlePreview = () => {
    return (
      <StyledDocViewer
        pluginRenderers={DocViewerRenderers}
        // eslint-disable-next-line global-require
        documents={[{ uri: link }]}
      />
    );
  };

  return (
    <Modal maxWidth="lg" title={title} open={open} onClose={onClose}>
      <CloseIcon sx={styles.closeButton} fontSize="medium" onClick={onClose} />
      <Box sx={styles.fieldContainer}>
        <Box sx={styles.agreement}>
          <Content ref={ref => setWrapperRef(ref)}>
            <RelativeList wrapperRef={wrapperRef}>{handlePreview()}</RelativeList>
          </Content>
        </Box>
      </Box>
    </Modal>
  );
}

export default memo(DocumentPreview);
