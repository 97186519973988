import { ChangeEvent, ComponentType, ElementType, ReactElement } from 'react';
import { useField } from 'formik';

interface Props<P> {
  name: string;
  component: ComponentType<P>;
  onChange?: (e: ChangeEvent<{ name?: string; value: string; key?: string }>) => void;
}

type WithoutInjectedProps<P> = Omit<P, 'name' | 'value' | 'error' | 'helperText' | 'onChange' | 'onBlur'>;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, react/function-component-definition
export const Field = <P extends unknown>({
  name,
  component,
  onChange,
  ...props
}: Props<P> & WithoutInjectedProps<P>): ReactElement => {
  const [field, meta] = useField<string>(name);

  const Component: ElementType = component;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    onChange?.(e);
  };

  return (
    <Component
      {...field}
      {...props}
      error={meta.touched && meta.error}
      onChange={handleChange}
      helperText={meta.touched && meta.error}
    />
  );
};
