const QUICK_START_FORM_MODULE = '[quick-start:form]';

const POST_QUICK_START_FORM = `${QUICK_START_FORM_MODULE} POST_QUICK_START_FORM`;
const SET_QUICK_START_STEP = `${QUICK_START_FORM_MODULE} SET_QUICK_START_STEP`;
const SET_QUICK_USER_DATA = `${QUICK_START_FORM_MODULE} SET_QUICK_USER_DATA`;
const POST_QUICK_LOGIN_FORM = `${QUICK_START_FORM_MODULE} POST_QUICK_LOGIN_FORM`;
const POST_QUICK_SIGNUP_FORM = `${QUICK_START_FORM_MODULE} POST_QUICK_SIGNUP_FORM`;

export const quickStartActionTypes = {
  POST_QUICK_START_FORM,
  SET_QUICK_START_STEP,
  SET_QUICK_USER_DATA,
  POST_QUICK_LOGIN_FORM,
  POST_QUICK_SIGNUP_FORM,
};
