import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { getStyles } from './styles';

export function QuickStartTitle(): ReactElement {
  const styles = getStyles();

  return (
    <>
      <Typography sx={styles.title1}>
        <Trans i18nKey="quick-start.title-1">Anyone can run ads.</Trans>
      </Typography>
      <Typography sx={styles.title2}>
        <Trans i18nKey="quick-start.title-2">Make yours unbeatable.</Trans>
      </Typography>
      <Typography sx={styles.desc}>
        <Trans i18nKey="quick-start.desc">
          To get started just enter your information, and we will get your Attribution campaigns up and running!
        </Trans>
      </Typography>
    </>
  );
}
