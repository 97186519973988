import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { quickStartActions } from 'modules/quick-start/store/actions';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const initialValues: QuickStartTypes.User = {
  email: '',
  password: '',
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().required().email().label(t('quick-start.email')),
    password: Yup.string().label(t('quick-start.password')).required(),
  });

interface Props {
  children: ReactNode;
}

export function QuickLoginForm({ children }: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = (values: QuickStartTypes.User) => {
    dispatch(quickStartActions.postQuickLoginForm({ email: values.email, password: values.password }));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      validateOnChange
    >
      {children}
    </Formik>
  );
}
