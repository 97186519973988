import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { quickStartActions } from 'modules/quick-start/store/actions';
import { QUICK_START_STEP } from 'modules/quick-start/constants';
import { getStyles } from './styles';

export function LoginLinkArea(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const handleLogin = () => {
    dispatch(quickStartActions.setQuickStartStep(QUICK_START_STEP.LOGIN));
  };

  return (
    <Typography sx={styles.login}>
      <Trans i18nKey="quick-start.login-text">Already have an account?</Trans>
      <Typography sx={styles.loginBtn} onClick={handleLogin}>
        <Trans i18nKey="quick-start.login-bttn">Login</Trans>
      </Typography>
    </Typography>
  );
}
