import { ApiService } from 'common/services';

class QuickStartApiService extends ApiService {
  postQuickStartInfo = (payload: QuickStartTypes.Form) => {
    return this.post('/api/v1/quick-onboarding', payload);
  };

  postQuickStartLogin = (payload: QuickStartTypes.User) => {
    return this.post('/api/v1/quick-onboarding/login', payload);
  };

  postQuickStartSignUp = (payload: QuickStartTypes.User) => {
    return this.post('/api/v1/quick-onboarding/user', payload);
  };

  postQuickStartBrand = (payload: QuickStartTypes.Form) => {
    return this.post('/api/v1/quick-onboarding/brand', payload);
  };
}

export const quickStartApi = new QuickStartApiService(
  process.env.REACT_APP_API_URL || 'https://register.prospected.ai/api'
);
