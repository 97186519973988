import { FocusEvent, forwardRef, ReactElement, Ref, useRef, useState } from 'react';
import styled from 'styled-components';
import { Overrides } from 'common/types';
import { TextFieldProps } from '@mui/material/TextField';
import CustomField from './custom-field';
import InputWrapper from './input-wrapper';
import { TextFieldClasses } from './types';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  ${({ overrides }: Overrides) => overrides};
`;

const ErrorLabel = styled.p`
  color: #fc4d67;
  font-size: 12px;
  ${({ overrides }: Overrides) => overrides};
  margin-top: 4px;
`;

const Label = styled.p`
  color: #7c8da5;
  font-size: 12px;
  margin-bottom: 4px;
  ${({ overrides }: Overrides) => overrides};
`;

interface Props {
  autocomplete?: boolean;
  error?: string;
  isTouched?: boolean;
  label?: string;
  startIcon?: ReactElement;
  classes?: TextFieldClasses;
  className?: string;
  borderRadius?: number;
}

const TextField = forwardRef(
  (
    {
      onBlur,
      onFocus,
      value,
      type,
      error,
      isTouched,
      placeholder,
      startIcon,
      label,
      classes,
      className,
      borderRadius,
      autocomplete,
      ...props
    }: TextFieldProps & Props,
    ref?: Ref<HTMLInputElement>
  ): ReactElement => {
    const inputRef = useRef<HTMLDivElement>(null);

    const [isFieldActive, setIsFieldActive] = useState<boolean>(false);

    const handleFieldFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(e);

      setIsFieldActive(true);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);

      setIsFieldActive(false);
    };

    return (
      <Root overrides={classes?.root} className={className}>
        {label && <Label overrides={classes?.label}>{label}</Label>}
        <InputWrapper
          error={Boolean(error && isTouched)}
          overrides={classes?.inputWrapper}
          active={isFieldActive}
          borderRadius={borderRadius}
          ref={inputRef}
        >
          {startIcon || ''}
          <CustomField
            autoComplete={autocomplete ? 'on' : 'off'}
            placeholder={placeholder}
            onFocus={handleFieldFocus}
            onBlur={handleBlur}
            value={value}
            type={type}
            overrides={classes?.input}
            ref={ref}
            className={className}
            {...props}
          />
        </InputWrapper>
        {error && isTouched && <ErrorLabel overrides={classes?.error}>{error}</ErrorLabel>}
      </Root>
    );
  }
);

export default TextField;
