import { ReactElement } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import TextField from 'common/ui/text-field';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { selectInviteExist, selectInviteLoading } from 'modules/team-invite/selectors';
import { ReactComponent as FirstItem } from 'common/svg/1.svg';
import { ReactComponent as SecondItem } from 'common/svg/2.svg';
import { selectLoginUrl, selectUserData } from 'common/store/selectors';

const getStyles = (): Styles => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  cardContent: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  fieldRowContainer: { display: 'flex', flexDirection: 'row' },
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  bttnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    mt: 1,
  },
  saveBttn: {
    border: '1px solid #C2DEEB',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '116px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#214254',
    fontSize: '16px',
    cursor: 'pointer',
  },
  reset: {
    cursor: 'pointer',
    fontSize: '12px',
    color: '#4288F0',
  },
});

const StyledField: SxProps = {
  width: '100%',
};

interface TeamInviteFormValues {
  firstName: string;
  lastName: string;
  password: string;
}

const FirstImageWrapper = styled.div`
  position: absolute;
  bottom: 56px;
  left: 81px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 1350px) {
    display: none;
  }
`;

const SecondImageWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 81px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 1350px) {
    display: none;
  }
`;

export function TeamInviteContent(): ReactElement {
  const styles = getStyles();
  const { submitForm } = useFormikContext<TeamInviteFormValues>();

  const loading = useSelector(selectInviteLoading);
  const isExist = useSelector(selectInviteExist);
  const loginUrl = useSelector(selectLoginUrl);
  const user = useSelector(selectUserData);

  const url = user?.is_brand ? 'https://brand.prospected.ai/' : 'https://carrick-ui.advoncommerce.com/';

  const handleOpenLogin = () => {
    window.open(loginUrl !== '' ? loginUrl : url);
  };

  return (
    <Box sx={styles.card}>
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box sx={styles.cardContent}>
          {isExist && <Typography variant="h5">You already have an account!</Typography>}
          {!isExist && (
            <Box sx={styles.fieldRowContainer}>
              <Box sx={styles.fieldContainer}>
                <Typography sx={styles.label}>First Name</Typography>
                <Box sx={styles.inputContainer}>
                  <Field component={TextField} name="firstName" placeholder="John" sx={{ ...StyledField }} />
                </Box>
              </Box>
              <Box sx={styles.fieldContainer}>
                <Typography sx={styles.label}>Last Name</Typography>
                <Box sx={styles.inputContainer}>
                  <Field component={TextField} name="lastName" placeholder="Smith" sx={{ ...StyledField }} />
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={styles.fieldContainer}>
            <Typography sx={styles.label}>{isExist ? 'Enter your existing Password' : 'Password'}</Typography>
            <Box sx={styles.inputContainer}>
              <Field
                component={TextField}
                type="password"
                name="password"
                placeholder="Password"
                sx={{ ...StyledField }}
              />
            </Box>
          </Box>
          {isExist && (
            <Box sx={styles.fieldContainer} onClick={handleOpenLogin}>
              <Typography sx={styles.reset}>Forgot your password? Reset on the Login Screen</Typography>
            </Box>
          )}
          <Box sx={styles.bttnWrapper}>
            <Box sx={styles.saveBttn} onClick={submitForm}>
              {isExist ? 'Submit' : 'Save'}
            </Box>
          </Box>
        </Box>
      )}
      <>
        <FirstImageWrapper>
          <FirstItem />
        </FirstImageWrapper>
        <SecondImageWrapper>
          <SecondItem />
        </SecondImageWrapper>
      </>
    </Box>
  );
}
