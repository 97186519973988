import { Action, RootStore } from 'common/interfaces/root-store';
import {
  INIT_REMOVE_LOADING,
  INIT_WITH_HASH,
  INIT_WITH_HASH_ERROR,
  INIT_WITH_HASH_SUCCESS,
  PUT_LANGUAGE,
  SIGN_UP_COMPLETE,
} from '../actions';
import { rootInitState } from '../init-state';

export default (state: RootStore = rootInitState, { type, payload }: Action): RootStore => {
  switch (type) {
    case PUT_LANGUAGE:
      return { ...state, lang: payload };

    case INIT_WITH_HASH:
      return { ...state, loading: true };

    case INIT_WITH_HASH_SUCCESS:
      return { ...state, loading: false, user_info: { ...state.user_info, ...payload } };

    case INIT_REMOVE_LOADING:
      return { ...state, loading: false };

    case INIT_WITH_HASH_ERROR:
      return { ...state, loading: false };

    case SIGN_UP_COMPLETE:
      return { ...state, isSignUpComplete: true, successUrl: payload };

    default:
      return state;
  }
};
