import { background } from 'common/constants/colors';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';

export const getStyles = (): Styles => ({
  contentBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    padding: '70px 110px 70px 160px',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: `${background}`,
    [theme.breakpoints.down(1270)]: {
      padding: '70px 70px 70px 120px',
    },
    [theme.breakpoints.down(1140)]: {
      width: '100%',
    },
    [theme.breakpoints.down(720)]: {
      padding: '35px 35px 35px 60px',
    },
  },
  inputWrapper: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 20px rgba(21, 30, 34, 0.1)',
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '20px',
    boxSizing: 'border-box',
  },
  asin: {
    marginTop: '10px',
  },
  connect: {
    fontFamily: 'Roboto',
    backgroundColor: '#7B45DD',
    width: '100%',
    color: '#ffffff',
    marginTop: '35px',
    height: '65px',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoWrapper: { position: 'absolute', top: 24 },
});
