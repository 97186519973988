import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { initRemoveLoading, initWithHash } from 'common/store/actions';
import { Styles } from 'common/types';
import { BackgroundImages } from 'common/ui/background-images';
import { Field } from 'common/ui/field';
import { useFormikContext } from 'formik';
import { getBrandsHub, getLoginUrl } from 'modules/sign-up/helpers';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import React, { ReactElement, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { ulid } from 'ulid';
import { isIgnite, theme } from '../../../../common/constants/theme';

const getStyles = (): Styles => ({
  card: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  fieldContainer: { width: '100%', m: '0 5px', mt: 4 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  label: { fontSize: 12, marginBottom: 1 },
  title: {
    mt: '12px',
    mb: '6px',
    fontWeight: 600,
  },
  nextStepBtn: {
    borderRadius: '12px',
    width: '100%',
    maxWidth: '116px',
    height: '39px',
    display: 'flex',
    fontSize: '16px',
    padding: '8px',
  },
  accountExistContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column' },
  bttnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    mt: 1,
  },
  loginWrapper: {
    mt: 1.7,
    display: 'flex',
    flexDirection: 'row',
    color: '#214254',
  },
  loginLink: {
    color: '#4288F0',
    cursor: 'pointer',
    ml: 0.5,
  },
});

enum TWhatAccountExist {
  CARRIC_DB,
  AOS_DB,
}

export function EmailCheck({
  setNewUserCheckPassed,
}: {
  setNewUserCheckPassed: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const dispatch = useDispatch();
  const { values, errors, getFieldMeta, setFieldTouched } = useFormikContext<SignUpFormValues>();
  const [isAccountExist, setIsAccountExist] = useState(false);
  const [whatAccountExist, setWhatAccountExist] = useState<TWhatAccountExist>();
  const [loading, setLoading] = useState(false);

  const styles = getStyles();

  const handleOpenLogin = () => {
    const url = getLoginUrl();
    window.open(url, '_blank');
  };

  const handleWhatAccountExist = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Account already exist':
        setWhatAccountExist(TWhatAccountExist.CARRIC_DB);
        setIsAccountExist(true);
        sessionStorage.removeItem('hash');
        break;
      case 'Email already exist':
        setWhatAccountExist(TWhatAccountExist.AOS_DB);
        setIsAccountExist(true);
        sessionStorage.removeItem('hash');
        break;
      default:
        toast.error(errorMessage);
    }
  };

  const renderForExistingEmail = () => {
    switch (whatAccountExist) {
      case TWhatAccountExist.CARRIC_DB:
        return (
          <Box sx={styles.accountExistContainer}>
            <img alt="contact-icon" src="assets/images/sheet_icon.png" />
            <Typography variant="h5" sx={styles.title}>
              You already have an account!
            </Typography>
            <Typography sx={styles.loginWrapper}>
              Already have an account?{' '}
              <Typography sx={styles.loginLink} onClick={handleOpenLogin}>
                Log In
              </Typography>
            </Typography>
          </Box>
        );
      case TWhatAccountExist.AOS_DB:
        return (
          <Box sx={styles.accountExistContainer}>
            <img alt="contact-icon" src="assets/images/sheet_icon.png" />
            <Typography variant="h5" sx={styles.title}>
              You already have an account!
            </Typography>
            <Typography>Please contact your rep to receive an invite!</Typography>
          </Box>
        );
      default:
        return (
          <Box sx={styles.accountExistContainer}>
            <img alt="contact-icon" src="assets/images/sheet_icon.png" />
            <Typography variant="h5" sx={styles.title}>
              Something went wrong
            </Typography>
          </Box>
        );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleNextStep = () => {
    const foundErrors = Object.entries(errors).filter(
      entry => ['email'].includes(entry[0]) && getFieldMeta(entry[0]).error
    );

    if (foundErrors.length) {
      toast.error('Enter required fields...');
      foundErrors.forEach(entry => setFieldTouched(entry[0]));
      return;
    }

    setLoading(true);
    const hash = sessionStorage.getItem('hash');
    const brandsHub = getBrandsHub();
    if (!hash) {
      const newHash = ulid();
      dispatch(
        initWithHash(newHash, values.email, brandsHub, () => setNewUserCheckPassed(true), handleWhatAccountExist)
      );
      dispatch(initRemoveLoading());
      sessionStorage.setItem('hash', newHash);
    } else {
      setNewUserCheckPassed(true);
      setIsAccountExist(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      handleNextStep();
    }
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.content}>
        {isAccountExist ? (
          renderForExistingEmail()
        ) : (
          <>
            <Typography variant="h4" sx={styles.title}>
              {`Your Amazon Attribution${isIgnite() ? '' : ' and Google Ads'} Co-Pilot!`}
            </Typography>
            <Typography>Please enter your email address below to get started.</Typography>
            <Box sx={styles.fieldContainer}>
              <Typography sx={styles.label}>Your email</Typography>
              <Box sx={styles.inputContainer}>
                <Field
                  component={TextField}
                  placeholder="e.g. hello@gmail.com"
                  name="email"
                  onKeyDown={handleEnterPress}
                  color="primary"
                  sx={{ width: '100%' }}
                  // classes={{ root: StyledField }}
                />
              </Box>
            </Box>
            <Box sx={styles.bttnWrapper}>
              <Button variant="contained" sx={styles.nextStepBtn} onClick={handleNextStep}>
                {loading ? <CircularProgress size={26} color="inherit" /> : 'Next'}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <BackgroundImages />
    </Box>
  );
}
