import { FunctionComponent } from 'react';
import { QuickStart, SignUp, TeamInvite } from 'modules';
import { isIgnite } from './theme';

interface Route {
  path: string;
  component: FunctionComponent;
}

export const getRoutes = (): Array<Route> => {
  if (isIgnite()) {
    return [
      {
        path: '/',
        component: SignUp,
      },
    ];
  }

  return [
    {
      path: '/',
      component: SignUp,
    },
    {
      path: '/invite-user',
      component: TeamInvite,
    },
    {
      path: '/invite-admin',
      component: TeamInvite,
    },
    {
      path: '/quick-start',
      component: QuickStart,
    },
  ];
};
