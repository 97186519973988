const TEAM_INVITE_MODULE = '[team-invite:info]';

const VERIFY_INVITATION_CODE = `${TEAM_INVITE_MODULE} VERIFY_INVITATION_CODE`;
const VERIFY_INVITATION_CODE_SUCCESS = `${TEAM_INVITE_MODULE} VERIFY_INVITATION_CODE_SUCCESS`;
const VERIFY_INVITATION_CODE_ERROR = `${TEAM_INVITE_MODULE} VERIFY_INVITATION_CODE_ERROR`;

const UPDATE_INVITATION_USER = `${TEAM_INVITE_MODULE} UPDATE_INVITATION_USER`;
const UPDATE_INVITATION_USER_SUCCESS = `${TEAM_INVITE_MODULE} UPDATE_INVITATION_USER_SUCCESS`;
const UPDATE_INVITATION_USER_ERROR = `${TEAM_INVITE_MODULE} UPDATE_INVITATION_USER_ERROR`;

export const teamInviteActionTypes = {
  VERIFY_INVITATION_CODE,
  VERIFY_INVITATION_CODE_SUCCESS,
  VERIFY_INVITATION_CODE_ERROR,
  UPDATE_INVITATION_USER,
  UPDATE_INVITATION_USER_SUCCESS,
  UPDATE_INVITATION_USER_ERROR,
};
