import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'common/hooks';
import { TeamInviteContent } from './components';
import { teamInviteActions } from './store/actions';
import { selectInviteError, selectInviteExist } from './selectors';
import { SuccessPage } from './components/success-page';
import { ErrorPage } from './components/error-page';

export interface TeamInviteFormValues {
  firstName: string;
  lastName: string;
  password: string;
}

export function TeamInvite(): ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const code = useQuery('code');

  const [successLink, setSuccessLink] = useState<string>(null);
  const isExist = useSelector(selectInviteExist);
  const inviteError = useSelector(selectInviteError);

  const getInitialValues: TeamInviteFormValues = {
    firstName: '',
    lastName: '',
    password: '',
  };

  const validationSchema = Yup.object({
    ...(!isExist ? { firstName: Yup.string().required('First name is a required field') } : {}),
    ...(!isExist ? { lastName: Yup.string().required('Last name is a required field') } : {}),
    password: Yup.string()
      .required('Password is a required field')
      .matches(
        /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/,
        'Password must be at least 6 characters, one letter and one number'
      ),
  });

  const handleSuccessInvite = (value: string) => {
    setSuccessLink(value);
  };

  const handleErrorInvite = (value: string) => {
    toast.error(value ?? 'Invite user error');
  };

  const handleFormSubmit = (values: TeamInviteFormValues) => {
    const path = location.pathname;
    dispatch(
      teamInviteActions.updateInvitationUser(
        {
          path,
          code,
          password: values.password,
          ...(!isExist ? { first_name: values.firstName } : {}),
          ...(!isExist ? { last_name: values.lastName } : {}),
        },
        handleSuccessInvite,
        handleErrorInvite
      )
    );
  };

  useEffect(() => {
    const path = location.pathname;
    dispatch(teamInviteActions.verifyInvationCode({ code, path }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const renderContent = () => {
    if (inviteError) return <ErrorPage />;
    if (successLink) return <SuccessPage successLink={successLink} />;
    return <TeamInviteContent />;
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={getInitialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
    >
      {renderContent()}
    </Formik>
  );
}
