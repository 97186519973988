import { ReactElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface WrapperProps {
  isHidden: boolean;
}

const Wrapper = styled.div`
  position: absolute;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ isHidden }: WrapperProps) => (isHidden ? 'transparent' : '#4e5f77')};
    width: 5px;
    height: 5px;
    margin: 6px 0;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

interface Props {
  children: ReactNode;
  wrapperRef?: HTMLElement | null;
  className?: string;
  mountScrollPosition?: 'start' | 'end';
  hiddenScroll?: boolean;
  stopDynamicPagination?: boolean;
  isNewMessages?: boolean;
  id?: string | number;
  setScrollTopPosition?: (value: number) => void;
}

function RelativeList({
  children,
  wrapperRef,
  className,
  mountScrollPosition,
  hiddenScroll = false,
  id,
  stopDynamicPagination,
  isNewMessages = false,
  setScrollTopPosition,
}: Props): ReactElement {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !wrapperRef) return;

    if (mountScrollPosition === 'end') {
      ref.current.scrollTop = ref.current.scrollHeight;
    } else {
      ref.current.scrollTop = 0;
    }
  }, [mountScrollPosition, wrapperRef]);

  useLayoutEffect(() => {
    if (!ref.current || !wrapperRef) return;

    const observer = new ResizeObserver(() => {
      if (!ref.current || !wrapperRef) return;
      ref.current.style.width = `${wrapperRef?.clientWidth}px`;
      ref.current.style.height = `${wrapperRef?.clientHeight}px`;
    });
    observer.observe(wrapperRef as Element);

    // eslint-disable-next-line consistent-return
    return () => observer.unobserve(wrapperRef as Element);
  }, [wrapperRef, id]);

  useLayoutEffect(() => {
    if (!wrapperRef || !ref.current) return;
    wrapperRef.style.position = 'relative';

    ref.current.style.width = `${wrapperRef.clientWidth}px`;
    ref.current.style.height = `${wrapperRef.clientHeight}px`;
  }, [wrapperRef, mountScrollPosition, id]);

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight - containerHeight;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMessages]);

  const scrollHandler = () => {
    if (setScrollTopPosition && !stopDynamicPagination) {
      setScrollTopPosition(ref.current.scrollTop);
      if (ref.current.scrollTop === 0 && !stopDynamicPagination) {
        setContainerHeight(ref.current.scrollHeight);
      }
    }
  };

  return (
    <Wrapper id="wrapper" className={className} isHidden={hiddenScroll} ref={ref} onScroll={scrollHandler}>
      {children}
    </Wrapper>
  );
}

export default RelativeList;
