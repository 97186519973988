import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root-store';
import { initWithHashError, initWithHashSuccess, setCompleteSignUp } from 'common/store/actions';
import { signUpApi } from 'modules/sign-up/service/settings.service';
import { signUpActions, signUpActionTypes as T } from 'modules/sign-up/store/actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* postBusinessInformation({ payload, meta }: Action) {
  try {
    yield call(signUpApi.postBusinessInformation, payload);
    yield put(
      signUpActions.setUserInfo({
        first_name: payload.data.first_name,
        last_name: payload.data.last_name,
      })
    );
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

function* postCompanyInformation({ payload, meta }: Action) {
  try {
    yield call(signUpApi.postCompanyInformation, payload);
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

function* postAdditionalInformation({ payload, meta }: Action) {
  try {
    yield call(signUpApi.postAdditionalInformation, payload);
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

function* postAgreementInformation({ payload, meta }: Action) {
  try {
    yield call(signUpApi.postAgreementInformation, payload);
    // yield put(signUpActions.addSignUrl(data.url));
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

function* postPaymentInformation({ payload, meta }: Action) {
  try {
    yield call(signUpApi.postPaymentInformation, payload);
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

function* getWallet({ payload, meta }: Action) {
  try {
    yield call(signUpApi.getWallet, payload);
    meta.onComplete(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onComplete(false);
  }
}

function* createWallet({ payload, meta }: Action) {
  try {
    yield call(signUpApi.createWallet, payload);
    const { data } = yield call(signUpApi.getWallet, payload);
    yield put(signUpActions.addCardData(data.cards));
    meta.onEnd(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const { data } = yield call(signUpApi.getWallet, payload);
    yield put(signUpActions.addCardData(data.cards));
    meta.onEnd(false);
  }
}

function* getUserInfo({ payload, meta }: Action) {
  try {
    const { data } = yield call(signUpApi.getUserInfo, payload);
    sessionStorage.setItem('hash', payload);
    yield put(initWithHashSuccess({ ...data.user, is_verified: true }));
    meta.onGenerated();
    // yield call(signUpApi.removeSignUp, payload.hash);
  } catch (error) {
    yield put(initWithHashError());
    meta.onError();
    meta.onGenerated();
  }
}

function* completeSignUp({ payload, meta }: Action) {
  try {
    const { data } = yield call(signUpApi.completeSignUp, payload);
    yield put(initWithHashSuccess({ ...data.user, is_verified: true }));
    yield put(setCompleteSignUp(data.url));
    sessionStorage.removeItem('hash');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    meta.onError(error.response.data.message);
  }
}

export function* signUpSaga(): SagaIterator {
  yield all([
    takeLatest(T.POST_BUSINESS_INFORMATION, postBusinessInformation),
    takeLatest(T.POST_ASIN_INFORMATION, postCompanyInformation),
    takeLatest(T.POST_ADDITIONAL_INFORMATION, postAdditionalInformation),
    takeLatest(T.POST_CONTRACT_TERMS, postAgreementInformation),
    takeLatest(T.POST_PAYMENT_INFORMATION, postPaymentInformation),
    takeLatest(T.GET_WALLET, getWallet),
    takeLatest(T.CREATE_WALLET, createWallet),
    takeLatest(T.GET_USER_INFO, getUserInfo),
    takeLatest(T.COMPLETE_SIGN_UP, completeSignUp),
  ]);
}
