import { Domains, BrandsHub } from '../../../common/constants/brands-hub';

export const getLoginUrl = (isBrand = false) => {
  switch (window.location.origin as Domains) {
    case Domains.IGNITE:
      return 'https://app.ignitecommerce.io/';
    default:
      return isBrand ? 'https://brand.prospected.ai/' : 'https://pub.prospected.ai/';
  }
};

export const getBrandsHub = () => {
  switch (window.location.origin as Domains) {
    case Domains.IGNITE:
      return BrandsHub.Ignite;
    default:
      return BrandsHub.MaverickX;
  }
};
