import axios, { AxiosResponse } from 'axios';

interface LatLong {
  lat: number;
  lng: number;
}

class GoogleApiService {
  API_ROUTE = 'https://maps.googleapis.com/maps/api/geocode';

  GOOGLE_TOKEN = 'AIzaSyAPRdAgY8v9XxwZau6znv7TwgkJNKBq6zM';

  getPlaceByCoordinates = ({ lat, lng }: LatLong): Promise<AxiosResponse> => {
    return axios.get(`${this.API_ROUTE}/json?latlng=${lat},${lng}&key=${this.GOOGLE_TOKEN}`);
  };

  getPlaceByAddress = (address: string): Promise<AxiosResponse> => {
    return axios.get(`${this.API_ROUTE}/json?address=${address}&key=${this.GOOGLE_TOKEN}`);
  };
}

export const GoogleApi = new GoogleApiService();
