import { Box, Divider } from '@mui/material';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { TextInput } from '../text-input';
import { getStyles } from './styles';
import { QuickStartTitle } from '../quick-start-title';

export function QuickStartContent(): ReactElement {
  const styles = getStyles();
  const { t } = useTranslation();

  const { submitForm } = useFormikContext<QuickStartTypes.Form>();

  return (
    <Box sx={styles.contentBar}>
      <Box sx={styles.content}>
        <QuickStartTitle />
        <Box sx={styles.inputWrapper}>
          <TextInput label={t('quick-start.brand-name')} name="company.name" />
          <Divider />
          <TextInput label={t('quick-start.storefront')} name="company.url" />
        </Box>
        <Box sx={styles.connect} onClick={submitForm}>
          <Trans i18nKey="quick-start.connect">Connect</Trans>
        </Box>
      </Box>
    </Box>
  );
}
