import { Box, Typography } from '@mui/material';
import { selectLoginUrl, selectUserData } from 'common/store/selectors';
import { Styles } from 'common/types';
import { BackgroundImages } from 'common/ui/background-images';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isIgnite, theme } from '../../../../common/constants/theme';
import { igniteText } from '../../../../common/constants/colors';

const getStyles = (): Styles => ({
  card: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  title: {
    mt: '12px',
    mb: '6px',
    fontWeight: 600,
  },
  accountExistContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column' },
  loginWrapper: {
    mt: 1.7,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: `${isIgnite() ? igniteText : '#214254'}`,
  },
  loginLink: {
    color: `${isIgnite() ? theme.palette.primary.dark : '#4288F0'}`,
    cursor: 'pointer',
    ml: 0.5,
  },
});

export function SignUpComplete(): ReactElement {
  const styles = getStyles();

  const loginUrl = useSelector(selectLoginUrl);
  const user = useSelector(selectUserData);

  const url = user?.is_brand ? 'https://brand.prospected.ai/' : 'https://carrick-ui.advoncommerce.com/';

  const handleOpenLogin = () => {
    let redirectUrl = '';

    if (isIgnite()) {
      redirectUrl = 'https://app.ignitecommerce.io';
    } else {
      redirectUrl = loginUrl !== '' ? loginUrl : url;
    }

    window.open(redirectUrl);
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.content}>
        <Box sx={styles.accountExistContainer}>
          <img alt="contact-icon" src="assets/images/sheet_icon.png" />
          <Typography variant="h5" sx={styles.title}>
            Thanks for registering!
          </Typography>
          <Typography sx={styles.loginWrapper}>
            Please login here{' '}
            <Typography sx={styles.loginLink} onClick={handleOpenLogin}>
              Log In
            </Typography>
          </Typography>
        </Box>
      </Box>
      <BackgroundImages />
    </Box>
  );
}
