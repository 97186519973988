import { CombinedState, combineReducers, Reducer } from 'redux';
import { signUpReducers } from 'modules/sign-up/store';
import { teamInviteReducers } from 'modules/team-invite/store';
import { quickStartReducers } from 'modules/quick-start/store';
import rootReducer from './root';

const reducers = combineReducers({
  root: rootReducer,
  signUp: signUpReducers,
  quickStart: quickStartReducers,
  teamInvite: teamInviteReducers,
});

export type State = ReturnType<typeof reducers>;

export default (): Reducer<CombinedState<State>> => reducers;
