import { Action } from 'common/interfaces/root-store';
import { QUICK_START_STEP } from 'modules/quick-start/constants';
import { quickStartActionTypes as T } from '../actions';

const initState: QuickStartTypes.QuickStartStore = {
  type: QUICK_START_STEP.START,
  userId: null,
};

export default (
  state: QuickStartTypes.QuickStartStore = initState,
  { type, payload }: Action
): QuickStartTypes.QuickStartStore => {
  switch (type) {
    case T.SET_QUICK_START_STEP:
      return { ...state, type: payload };
    case T.SET_QUICK_USER_DATA:
      return { ...state, type: QUICK_START_STEP.BRAND, userId: payload };
    default:
      return state;
  }
};
