import { Box, Checkbox, Typography, TextField } from '@mui/material';
import { Styles } from 'common/types';
import AddressInput from 'common/ui/address-input';
import { Field } from 'common/ui/field';
import { useFormikContext } from 'formik';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { ReactElement } from 'react';
import { isIgnite } from '../../../../common/constants/theme';

const styles: Styles = {
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  fieldRowContainer: {
    width: '100%',
    m: '0 5px',
    mt: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  field: { width: '100%' },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  selectBttnWrapper: { display: 'flex', flexDirection: 'row' },
  selectBttn: {
    p: '0 20px 0 10px',
    boxSizing: 'border-box',
    m: '0 5px',
    mt: 2,
    cursor: 'pointer',
    borderRadius: '12px',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toogleWrapper: {
    height: '48px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '0 20px 0 10px',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },
};

export function AsinInformation(): ReactElement {
  const { values, setFieldValue } = useFormikContext<SignUpFormValues>();

  const handleChangeIsBrand = (value: boolean) => {
    setFieldValue('isBrand', value);
  };

  const handleChangeAddress = (value: string) => {
    setFieldValue('address', value);
  };

  return (
    <>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Company name</Typography>
        <Box sx={styles.inputContainer}>
          <Field component={TextField} placeholder="e.g. Acme Inc" name="companyName" sx={{ width: '100%' }} />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Company Address</Typography>
        <Box sx={styles.inputContainer}>
          <AddressInput
            value={values.address}
            placeholder="e.g. 705 Acme Dr"
            onChange={handleChangeAddress}
            sx={styles.field}
          />
        </Box>
      </Box>
      {!isIgnite() && (
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Select your account type</Typography>
          <Box sx={styles.selectBttnWrapper}>
            <Box sx={styles.selectBttn} onClick={() => handleChangeIsBrand(false)}>
              <Checkbox checked={!values.isBrand} />
              <Typography>Publisher</Typography>
            </Box>
            <Box sx={styles.selectBttn} onClick={() => handleChangeIsBrand(true)}>
              <Checkbox checked={values.isBrand} />
              <Typography>Brand</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {values.isBrand ? (
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>URL</Typography>
          <Box sx={styles.inputContainer}>
            <Field component={TextField} placeholder="Amazon Store URL (https://...)" name="domain" sx={styles.field} />
          </Box>
        </Box>
      ) : (
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Website URL</Typography>
          <Box sx={styles.inputContainer}>
            <Field component={TextField} placeholder="e.g. domain.com" name="domain" sx={styles.field} />
          </Box>
        </Box>
      )}
    </>
  );
}
