export const chTranslations = {
  'quick-start.title-1': '任何人都可以投放广告。',
  'quick-start.title-2': '让你无与伦比。',
  'quick-start.desc': '要开始，只需输入您的信息，我们就会启动并运行您的归因活动！',
  'quick-start.sign-up': '注册',
  'quick-start.login-text': '已有账户？',
  'quick-start.login-bttn': '登录',
  'quick-start.our-solution': '我们的解决方案',
  'quick-start.competitor-direct': '竞争对手直接',
  'quick-start.product-sales': '产品销售',
  'quick-start.continue': '继续',
  'quick-start.email': '电子邮件',
  'quick-start.password': '密码',
  'quick-start.confirm-password': '确认密码',
  'quick-start.brand-name': '品牌名称',
  'quick-start.storefront': '店面网址',
  'quick-start.connect': '连接',
  'quick-start.password-validation-1': '密码必须至少8个字符，一个大小写字母和一个数字',
  'quick-start.password-validation-2': '密码必须匹配',
};
