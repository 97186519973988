import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Styles } from 'common/types';
import { useFormikContext } from 'formik';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { StripeForm } from './stripe-form';

const styles: Styles = {
  fieldRowContainer: { display: 'flex', flexDirection: 'row' },
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  cellText: { color: '#214254', fontSize: '12px' },
  title: { fontWeight: 600, fontSize: '18px', mt: 2 },
  radioGroup: { mt: 2 },
  asinInputsContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr 2fr',
    columnGap: '16px',
    rowGap: '16px',
    marginTop: '16px',
  },
  addAsinBtn: {
    mt: 2,
    display: 'flex',
    justifyContent: 'left',
    color: '#4288F0',
    fontSize: '16px',
    cursor: 'pointer',
  },
  lastCol: {
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    columnGap: '6px',
  },
  deleteBtn: {
    cursor: 'pointer',
  },
  deleteBtnPlace: {
    width: '60px',
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey',
    paddingBottom: 1,
    marginY: 2,
  },
  totalNumber: {
    fontWeight: 600,
    fontSize: '18px',
  },
};

interface Props {
  isGeneratingToken: boolean;
  createWallet: (value: string) => void;
  setIsGeneratingToken: (value: boolean) => void;
}

export interface IAsinData {
  asin: string;
  category: Category;
  budget: number;
}

enum Category {
  High_Performing_ASIN = 'High Performing ASIN',
  Consumable_Product = 'Consumable Product',
  New_Product_Launch = 'New Product Launch',
  Underperforming_Product = 'Underperforming Product',
  Overstock_Inventory = 'Overstock Inventory',
}

export function PaymentInformation({ isGeneratingToken, createWallet, setIsGeneratingToken }: Props): ReactElement {
  const { setFieldValue } = useFormikContext<SignUpFormValues>();

  const asinsListMock = [{ asin: '', category: Category.High_Performing_ASIN, budget: 500 }];
  const [asinData, setAsinData] = useState<IAsinData[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY), []);

  const handleAddAsin = () => {
    setAsinData([...asinData, ...asinsListMock]);
  };

  const handleRemoveAsin = (pos: number) => {
    setAsinData(asinData.filter((item, idx) => idx !== pos));
  };

  const handleChangeAsin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, pos: number) => {
    const newItems = [...asinData];
    newItems[pos].asin = e.target.value;
    setAsinData(newItems);
  };

  const handleChageCategory = (e: SelectChangeEvent, pos: number) => {
    const newItems = [...asinData];
    newItems[pos].category = e.target.value as Category;
    setAsinData(newItems);
  };

  const handleChangeBudget = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, pos: number) => {
    const newItems = [...asinData];
    let { value } = e.target;
    if (value === '') {
      value = '0';
    }
    if (!/^[0-9]+$/.test(value)) {
      return;
    }
    newItems[pos].budget = Number(value);
    setAsinData(newItems);
  };

  useEffect(() => {
    setTotalPrice(asinData.reduce((a, b) => a + b.budget, 0));
    setFieldValue('orders', asinData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asinData]);

  useEffect(() => {
    handleAddAsin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography sx={styles.title}>Your Order</Typography>
      <Box sx={styles.asinInputsContainer}>
        <Typography sx={styles.cellText}>ASIN</Typography>
        <Typography sx={styles.cellText}>Category</Typography>
        <Typography sx={styles.cellText}>Budget, $</Typography>
        {asinData.map((item, idx) => (
          <>
            <TextField
              value={item.asin}
              size="small"
              onChange={e => handleChangeAsin(e, idx)}
              error={item.asin.length < 3}
            />
            <Select value={item.category} size="small" onChange={(e: SelectChangeEvent) => handleChageCategory(e, idx)}>
              <MenuItem value={Category.High_Performing_ASIN}>{Category.High_Performing_ASIN}</MenuItem>
              <MenuItem value={Category.Consumable_Product}>{Category.Consumable_Product}</MenuItem>
              <MenuItem value={Category.New_Product_Launch}>{Category.New_Product_Launch}</MenuItem>
              <MenuItem value={Category.Underperforming_Product}>{Category.Underperforming_Product}</MenuItem>
              <MenuItem value={Category.Overstock_Inventory}>{Category.Overstock_Inventory}</MenuItem>
            </Select>
            <Box sx={styles.lastCol}>
              <TextField
                value={item.budget}
                size="small"
                label="500-5000"
                onChange={e => handleChangeBudget(e, idx)}
                error={item.budget < 500 || item.budget > 5000}
              />
              {idx !== 0 ? (
                <CloseIcon sx={styles.deleteBtn} fontSize="small" onClick={() => handleRemoveAsin(idx)} />
              ) : (
                <Box sx={styles.deleteBtnPlace} />
              )}
            </Box>
          </>
        ))}
      </Box>

      {asinData.length < 10 && (
        <Box sx={styles.addAsinBtn} onClick={handleAddAsin}>
          + Add another ASIN
        </Box>
      )}

      <Box sx={styles.totalContainer}>
        <Typography sx={styles.cellText}>Total</Typography>
        <Typography sx={styles.totalNumber}>${totalPrice}</Typography>
      </Box>

      <Typography sx={styles.title}>Payment</Typography>

      <Elements stripe={stripePromise}>
        <StripeForm
          isGeneratingToken={isGeneratingToken}
          setIsGeneratingToken={setIsGeneratingToken}
          createWallet={createWallet}
        />
      </Elements>
    </>
  );
}
