import { ReactElement, useLayoutEffect, memo, useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { Styles } from 'common/types';
import { COMPANY_AGREEMENT, COMPANY_AGREEMENT_MAP } from 'modules/sign-up/constants';
import './sign-canvas.css';
// import DocViewer from '@cyntler/react-doc-viewer';
import { useSelector } from 'react-redux';
import { selectLoadingUrl, selectSignUrl } from 'modules/sign-up/store/selectors';
import DocumentPreview from './document-preview';

const styles: Styles = {
  fieldRowContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  fieldContainer: { width: '100%', m: '0 5px', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1, mt: 1 },
  bttnWrapper: { display: 'flex', justifyContent: 'center', width: '100%' },
  agreement: {
    textAlign: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    border: '1px solid #C2DEEB',
    borderRadius: '12px',
  },
  signWrapper: {
    width: '100%',
    border: '1px solid #C2DEEB',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '96px',
  },
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
  agreeContainer: {
    border: '1px dashed #C2DEEB',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    p: '10px',
    boxSizing: 'border-box',
  },
  link: {
    marginLeft: '5px',
    cursor: 'pointer',
    color: '#4288F0',
  },
  agreeText: {
    display: 'flex',
    flexDirection: 'row',
  },
  disablePointer: {
    cursor: 'default',
  },
};

function ContractTerms(): ReactElement {
  const { values, setFieldValue } = useFormikContext<SignUpFormValues>();

  const [docLink, setDocLink] = useState(null);
  const [docTitle, setDocTitle] = useState('');

  const urlSign = useSelector(selectSignUrl);
  const loading = useSelector(selectLoadingUrl);

  const handleCheckServiceSummary = (value: boolean) => {
    setFieldValue('summaryService', value);
  };

  const handleCheckAcceptedTerms = (value: boolean) => {
    setFieldValue('termsAndConditionals', value);
  };

  const handleSetDocLink = (value: COMPANY_AGREEMENT) => {
    switch (value) {
      case COMPANY_AGREEMENT.service_summary:
        handleCheckServiceSummary(true);
        break;
      case COMPANY_AGREEMENT.terms_and_conditions:
        handleCheckAcceptedTerms(true);
        break;
      default:
        break;
    }
    setDocLink(COMPANY_AGREEMENT_MAP[value].link);
    setDocTitle(COMPANY_AGREEMENT_MAP[value].title);
  };

  const handleRemoveDocLink = () => {
    setDocLink(null);
  };

  useLayoutEffect(() => {
    if (loading) return;

    // remove header from dom, because removing header via component config crash pdf renderer
    setTimeout(() => {
      document.querySelector('#header-bar')?.remove();
    });
  }, [urlSign, loading]);

  return (
    <>
      <Box sx={styles.fieldContainer}>
        <Box sx={styles.agreeContainer}>
          <Box sx={{ ...styles.fieldRowContainer, borderBottom: '1px solid #c2deeb7b' }}>
            <Checkbox
              sx={styles.disablePointer}
              checked={values.summaryService}
              onChange={() => {
                handleSetDocLink(COMPANY_AGREEMENT.service_summary);
              }}
            />
            <Typography sx={styles.agreeText}>
              Please agree to our
              <Typography
                sx={styles.link}
                onClick={() => {
                  handleSetDocLink(COMPANY_AGREEMENT.service_summary);
                }}
              >
                Service Summary
              </Typography>
            </Typography>
          </Box>
          <Box sx={styles.fieldRowContainer}>
            <Checkbox
              sx={styles.disablePointer}
              checked={values.termsAndConditionals}
              onChange={() => {
                handleSetDocLink(COMPANY_AGREEMENT.terms_and_conditions);
              }}
            />
            <Typography sx={styles.agreeText}>
              Please agree to our
              <Typography
                sx={styles.link}
                onClick={() => {
                  handleSetDocLink(COMPANY_AGREEMENT.terms_and_conditions);
                }}
              >
                Terms and Conditions
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <DocumentPreview open={Boolean(docLink)} title={docTitle} link={docLink} onClose={handleRemoveDocLink} />
    </>
  );
}

export default memo(ContractTerms);
