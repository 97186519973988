import { RootStore } from 'common/interfaces/root-store';

export const rootInitState: RootStore = {
  lang: 'en',
  loading: true,
  isSignUpComplete: false,
  successUrl: '',
  user_info: {
    acct_contact_email: '',
    acct_contact_first_name: '',
    acct_contact_last_name: '',
    address: '',
    ar_contact_email: '',
    ar_contact_first_name: '',
    ar_contact_last_name: '',
    company_name: '',
    domain: '',
    email: '',
    first_name: '',
    is_affiliate_content: false,
    is_brand: true,
    last_name: '',
    password: '',
    phone: '',
    platforms_amazon_ca: false,
    platforms_amazon_uk: false,
    platforms_amazon_us: true,
    platforms_other: false,
    signature_filename: '',
    is_verified: false,
  },
};
