import { useQuery } from 'common/hooks';
import { Formik } from 'formik';
import { quickStartActions } from 'modules/quick-start/store/actions';
import { selectQuickStartUserId } from 'modules/quick-start/store/selectors';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { isIgnite } from 'common/constants/theme';
import { BrandsHub } from 'common/constants/brands-hub';

const initialValues: QuickStartTypes.Form = {
  company: {
    name: '',
    url: '',
    brandsHub: isIgnite() ? BrandsHub.Ignite : BrandsHub.MaverickX,
  },
};

interface Props {
  children: ReactNode;
}

export function QuickStartForm({ children }: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectQuickStartUserId);
  const ref = useQuery('ref');
  const validationSchema = yup.object({
    company: yup.object().shape({
      name: yup.string().required().label(t('quick-start.brand-name')),
      url: yup.string().required().label(t('quick-start.storefront')),
    }),
  });

  const handleSubmit = (values: QuickStartTypes.Form) => {
    dispatch(
      quickStartActions.postQuickStartForm({
        company: { ...values.company },
        user_id: userId,
        ...(ref === null ? {} : { ref }),
      })
    );
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      {children}
    </Formik>
  );
}
