import { teamInviteActionTypes as T } from './action-types';

const updateInvitationUser = (
  payload: TeamInviteModule.TeamInviteInformation,
  onSuccess: (value: string) => void,
  onError?: (value: string) => void
) => ({
  type: T.UPDATE_INVITATION_USER,
  payload,
  meta: { onSuccess, onError },
});

const updateInvitationUserSuccess = () => ({
  type: T.UPDATE_INVITATION_USER_SUCCESS,
});

const updateInvitationUserError = (error: Error) => ({
  type: T.UPDATE_INVITATION_USER_ERROR,
  payload: { error },
});

const verifyInvationCode = (payload: { code: string; path: string }) => ({
  type: T.VERIFY_INVITATION_CODE,
  payload,
});

const verifyInvationCodeSuccess = (payload: boolean) => ({
  type: T.VERIFY_INVITATION_CODE_SUCCESS,
  payload,
});

const verifyInvationCodeError = (error: Error) => ({
  type: T.VERIFY_INVITATION_CODE_ERROR,
  payload: { error },
});

export const teamInviteActions = {
  updateInvitationUser,
  updateInvitationUserSuccess,
  updateInvitationUserError,
  verifyInvationCode,
  verifyInvationCodeSuccess,
  verifyInvationCodeError,
};
