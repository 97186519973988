import { SagaIterator } from '@redux-saga/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_PLACE_COORDINATES,
  GET_PLACE_NAME,
  initWithHashError,
  initWithHashSuccess,
  INIT_WITH_HASH,
  VERIFY_INVITE_LINK,
} from 'common/store/actions';
import { signUpSaga } from 'modules/sign-up/store';
import { teamInviteSaga } from 'modules/team-invite/store';
import { Action } from 'common/interfaces/root-store';
import { rootApi } from 'common/services/root.api';
import { signUpApi } from 'modules/sign-up/service/settings.service';
import { quickStartSaga } from 'modules/quick-start/store';
import { getPlaceCoordinates, getPlaceName } from './google-map-saga';

function* initWithHash({ payload, meta }: Action): SagaIterator {
  try {
    yield call(rootApi.initWithHash, payload);
    meta.onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    meta.onError(err.response.data.message);
  }
}

function* verifyInviteLink({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(rootApi.verifyInviteLink, payload);
    const info = yield call(signUpApi.getUserInfo, data.hash);
    sessionStorage.setItem('hash', data.hash);
    yield put(initWithHashSuccess({ ...info.data.user, is_verified: true }));
    meta.onGenerated();
  } catch {
    yield put(initWithHashError());
    meta.onGenerated();
  }
}

function* rootSaga(): SagaIterator {
  yield all([
    takeEvery(GET_PLACE_NAME, getPlaceName),
    takeEvery(GET_PLACE_COORDINATES, getPlaceCoordinates),
    takeEvery(INIT_WITH_HASH, initWithHash),
    takeEvery(VERIFY_INVITE_LINK, verifyInviteLink),
    fork(signUpSaga),
    fork(teamInviteSaga),
    fork(quickStartSaga),
  ]);
}

export default rootSaga;
