import { Overrides } from 'common/types';
import styled from 'styled-components';

const getFieldBorderColor = (active: boolean, error: boolean) => {
  let color = '#c7d1de';

  if (active && !error) color = '#2A7CF0';
  if (error) color = '#FC4D67';

  return color;
};

interface InputWrapperProps extends Overrides {
  active: boolean;
  error: boolean;
  borderRadius?: number;
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid ${({ active, error }: InputWrapperProps) => getFieldBorderColor(active, error)};
  padding: 0px 8px;
  border-radius: ${({ borderRadius }: InputWrapperProps) => (borderRadius ? `${borderRadius}px` : '4px')};
  box-sizing: border-box;
  ${({ overrides }: InputWrapperProps) => overrides};
`;

export default InputWrapper;
