import { createTheme } from '@mui/material';
import { background, green4, igniteText, input, menu, orange, paper, primary, primaryLight, stroke } from './colors';
import { Domains } from './brands-hub';

const mavxTheme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
    },
    background: {
      default: background,
      paper,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  },
  components: { MuiTextField: { styleOverrides: {} } },
});

const igniteTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: menu,
      paper: input,
    },
    primary: { dark: orange, main: orange },
    secondary: { main: input, dark: input },
    divider: stroke,
    warning: {
      main: orange,
    },
    info: {
      main: green4,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  },
  components: {
    MuiTypography: { styleOverrides: { root: { color: igniteText } } },
    MuiTextField: { styleOverrides: { root: { color: igniteText, borderRadius: '8px' } } },
    MuiInputBase: { styleOverrides: { root: { borderRadius: '8px', color: igniteText } } },
    MuiOutlinedInput: { styleOverrides: { root: { borderRadius: '8px', color: igniteText } } },
    MuiFilledInput: { styleOverrides: { root: { borderRadius: '8px', color: igniteText } } },
  },
});

export const getTheme = (domain?: Domains) => {
  if (domain === Domains.IGNITE) return igniteTheme;

  return mavxTheme;
};

export const theme = getTheme(window.location.origin as Domains);

export const isIgnite = (domain = window.location.origin): domain is Domains.IGNITE => {
  return domain === Domains.IGNITE;
};
