import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { paper, primary } from 'common/constants/colors';
import { Styles } from 'common/types';
import { ReactElement, ReactNode } from 'react';

const styles: Styles = {
  title: { backgroundColor: primary, color: paper, fontSize: '24px' },
  content: { padding: 0 },
  contentWrapper: { padding: 3 },
};

interface Props {
  title: string;
  children: ReactNode;
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClose: () => void;
}

export function Modal({ title, children, open, onClose, maxWidth = 'sm' }: Props): ReactElement {
  return (
    <Dialog maxWidth={maxWidth} fullWidth open={open} onClose={onClose}>
      <DialogTitle sx={styles.title}>{title}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.contentWrapper}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
