import { Box, Typography } from '@mui/material';
import { useWindowSize } from 'common/hooks';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as ArrowUpIcon } from 'common/svg/arrow-up.svg';
import { ReactComponent as LightningIcon } from 'common/svg/lightning.svg';
import { ReactComponent as LightningShadowIcon } from 'common/svg/lightning-shadow.svg';
import { getStyles } from './styles';

export function RightSideContent(): ReactElement {
  const { width } = useWindowSize();
  const styles = getStyles();

  const isExtraIconsHidden = width <= 1450;
  const isRightSideHidden = width <= 1140;

  if (isRightSideHidden) return null;

  return (
    <Box sx={{ ...styles.contentBar, backgroundColor: '#7B45DD' }}>
      <Box sx={styles.rightWrapper}>
        {!isExtraIconsHidden && (
          <Box sx={styles.topIconsWrapper}>
            <Box sx={styles.wBox} />
            <Box sx={styles.sBox} />
          </Box>
        )}
        {!isExtraIconsHidden && (
          <Box sx={styles.rightBlockAdditional}>
            <Box sx={styles.skeleton} />
            <Box sx={styles.sumPerWrapper}>
              <Typography sx={styles.sum}>$181,74</Typography>
              <Box sx={styles.sumValWrapper}>
                <ArrowUpIcon />
                <Typography sx={styles.sumValue}>20%</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={styles.rightBlock}>
          <img
            style={{ width: '500px', borderRadius: '12px' }}
            alt="new-balance-icon"
            src="assets/images/new-balance.png"
          />
          <Box sx={styles.subBlocksWrapper}>
            <Box sx={styles.subBlock}>
              <Typography sx={styles.subBlockTitle}>
                <Trans i18nKey="quick-start.our-solution">Our Solution</Trans>
              </Typography>
              <Box sx={styles.salesProgressBar}>
                <Typography sx={styles.subBlockValue}>$1146</Typography>
              </Box>
              <Box
                sx={{
                  ...styles.salesProgressBar,
                  background: 'linear-gradient(to left, #ECF2F4 90%, #a6a2d4 90%)',
                }}
              >
                <Typography sx={styles.subBlockValue}>8%</Typography>
              </Box>
            </Box>
            <Box sx={styles.subBlock}>
              <Typography sx={styles.vsTitle}>vs</Typography>
              <Typography sx={styles.progressTitle}>
                <Trans i18nKey="quick-start.product-sales">Product Sales</Trans>
              </Typography>
              <Typography sx={styles.progressTitle}>ACOS</Typography>
            </Box>
            <Box sx={styles.subBlock}>
              <Typography sx={styles.subBlockTitle}>
                <Trans i18nKey="quick-start.competitor-direct">Competitor Direct</Trans>
              </Typography>
              <Box
                sx={{
                  ...styles.salesProgressBar,
                  background: 'linear-gradient(to left, #ECF2F4 20%, #a6a2d4 20%)',
                }}
              >
                <Typography sx={styles.subBlockValue}>$945</Typography>
              </Box>
              <Box sx={styles.salesProgressBar}>
                <Typography sx={styles.subBlockValue}>121%</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {!isExtraIconsHidden && (
          <Box sx={styles.bottomIconsWrapper}>
            <Box sx={styles.lightningWrapper}>
              <LightningIcon />
            </Box>
            <Box sx={styles.lightningShadowWrapper}>
              <LightningShadowIcon />
            </Box>
            <Box sx={styles.mBox} />
            <Box sx={styles.lBox} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
