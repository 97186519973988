import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root-store';
import { GoogleApi } from 'common/services/google.api';
import { call, put } from 'redux-saga/effects';

export function* getPlaceName({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(GoogleApi.getPlaceByCoordinates, payload);

    yield put(meta.putPlaceName(data.results[0] ? data.results[0].formatted_address : ''));
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function* getPlaceCoordinates({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(GoogleApi.getPlaceByAddress, payload);
    yield put(meta.putLatLong(data.results[0] ? data.results[0].geometry.location : { lat: 0, lng: 0 }));
    // eslint-disable-next-line no-empty
  } catch (error) {}
}
