import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

interface ProgressLineProps {
  progressWidth: string;
  progressColor: string;
}

const getStyles = ({ progressColor, progressWidth }: ProgressLineProps): Styles => ({
  progress: {
    backgroundColor: progressColor,
    width: progressWidth,
    height: '3px',
    transition: 'width 0.3s ease-in-out',
    mt: '35px',
    borderRadius: '30px',
  },
});

interface Props {
  stepsCount: number;
  currentStep: number;
  color: string;
}

export function ProgressBar({ stepsCount, currentStep, color = '#4288F0' }: Props): ReactElement {
  const styles = getStyles({ progressColor: color, progressWidth: `${(currentStep / stepsCount) * 100}%` });
  return <Box sx={styles.progress} />;
}
