import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { quickStartActions } from 'modules/quick-start/store/actions';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const initialValues: QuickStartTypes.User = {
  email: '',
  password: '',
  confirmPassword: '',
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().required().email().label(t('quick-start.email')),
    password: Yup.string()
      .label(t('quick-start.password'))
      .required()
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\d@$!%*?&]{8,}$/, t('quick-start.password-validation-1')),
    confirmPassword: Yup.string()
      .label(t('quick-start.password'))
      .required()
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\d@$!%*?&]{8,}$/, t('quick-start.password-validation-1'))
      .test('passwords-match', t('quick-start.password-validation-2'), function (value) {
        return this.parent.password === value;
      }),
  });

interface Props {
  children: ReactNode;
}

export function QuickSignUpForm({ children }: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: QuickStartTypes.User) => {
    dispatch(quickStartActions.postQuickSignUpForm({ email: values.email, password: values.password }));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      validateOnChange
    >
      {children}
    </Formik>
  );
}
