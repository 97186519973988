import { Box, Tooltip, Typography } from '@mui/material';
import { ReactElement } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Styles } from 'common/types';
import ErrorIcon from '@mui/icons-material/Error';
import { theme } from 'common/constants/theme';
import { useField } from 'formik';

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2, 0),
    width: '100%',
    boxSizing: 'border-box',
  },
  label: { color: '#214254', fontSize: 11 },
  inputWrapper: { display: 'flex', alignItems: 'center' },
  input: { all: 'unset', flexGrow: 1, height: 40, color: '#060F14', fontSize: 16 },
  errorIcon: { cursor: 'pointer' },
};

interface Props {
  label: string;
  name: string;
  type?: 'password';
}

export function TextInput({ label, name, type }: Props): ReactElement {
  const [inputProps, metaProps] = useField(name);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <Box sx={styles.inputWrapper}>
        <Box sx={styles.input} component="input" type={type} {...inputProps} />
        {metaProps.error && metaProps.touched && (
          <Tooltip title={metaProps.error} placement="top">
            <ErrorIcon color="error" sx={styles.errorIcon} fontSize="small" />
          </Tooltip>
        )}
        {!metaProps.error && metaProps.touched && <CheckIcon color="success" fontSize="small" />}
      </Box>
    </Box>
  );
}
