/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { useSelector } from 'react-redux';
import { selectQuickStartType } from './store/selectors';
import { QuickStartContent, QuickStartForm, RightSideContent } from './components';
import { QuickStartSelect } from './components/quick-start-select';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import { QUICK_START_STEP } from './constants';
import { QuickLoginForm } from './components/quick-login-form';
import { QuickSignUpForm } from './components/quick-sign-up-form';
import { QuickAuthContent } from './components/quick-auth-content';

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
};

export function QuickStart(): ReactElement {
  const type = useSelector(selectQuickStartType);

  const renderCurrentStep = () => {
    switch (type) {
      case QUICK_START_STEP.START:
        return <QuickStartSelect />;

      case QUICK_START_STEP.LOGIN:
        return (
          <QuickLoginForm>
            <QuickAuthContent type={QUICK_START_STEP.LOGIN} />
          </QuickLoginForm>
        );

      case QUICK_START_STEP.SIGNUP:
        return (
          <QuickSignUpForm>
            <QuickAuthContent type={QUICK_START_STEP.SIGNUP} />
          </QuickSignUpForm>
        );

      case QUICK_START_STEP.BRAND:
        return (
          <QuickStartForm>
            <QuickStartContent />
          </QuickStartForm>
        );

      default:
        return <QuickStartSelect />;
    }
  };

  return (
    <Box sx={styles.wrapper}>
      {renderCurrentStep()}
      <RightSideContent />
    </Box>
  );
}
