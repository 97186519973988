const API_TOKEN = 'AIzaSyAPRdAgY8v9XxwZau6znv7TwgkJNKBq6zM';

export const loadGoogleScript = (cb: () => void): void => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_TOKEN}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (cb) cb();
    };
  }

  if (existingScript && cb) cb();
};
