import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { BackgroundImages } from 'common/ui/background-images';

const getStyles = (): Styles => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  cardContent: {
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    p: '45px 53px',
    boxSizing: 'border-box',
    '@media (max-width:780px)': {
      p: '45px 20px',
    },
  },
  accountTitle: {
    mt: '12px',
    mb: '6px',
    fontWeight: 600,
  },
  accountExistContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

interface Props {
  successLink: string;
}

export function SuccessPage({ successLink }: Props): ReactElement {
  const styles = getStyles();

  const handleRedirectLink = () => {
    window.open(successLink, '_self');
  };

  return (
    <Box sx={styles.card} onClick={handleRedirectLink}>
      <Box sx={styles.cardContent}>
        <Box sx={styles.accountExistContainer}>
          <Typography variant="h5" sx={styles.accountTitle}>
            User has been added!
          </Typography>
          <Typography>Please click here to login to your new account.</Typography>
        </Box>
      </Box>
      <BackgroundImages />
    </Box>
  );
}
