export enum SIGN_UP_STEP {
  ASIN_INFORMATION = 1,
  BUSINESS_INFORMATION,
  ADDITIONAL_INFORMATION,
  CONTRACT_TERMS,
  PAYMENT_INFORMATION,
}

export const STEP_TITLE_MAP = {
  [SIGN_UP_STEP.ASIN_INFORMATION]: {
    name: 'Getting Started',
    title: 'Welcome! Let’s Get Started.',
    step: 1,
  },
  [SIGN_UP_STEP.BUSINESS_INFORMATION]: {
    name: 'Account Registration',
    title: 'Account Registration',
    step: 2,
  },
  [SIGN_UP_STEP.ADDITIONAL_INFORMATION]: {
    name: 'Additional information',
    title: 'Additional information',
    step: 3,
  },
  [SIGN_UP_STEP.CONTRACT_TERMS]: {
    name: 'Contract Terms and Agreement',
    title: 'Contract Terms and Agreement',
    step: 4,
  },
  [SIGN_UP_STEP.PAYMENT_INFORMATION]: {
    name: 'Payment information',
    title: 'Payment information',
    step: 5,
  },
};

export enum COMPANY_AGREEMENT {
  service_summary = 'service_summary',
  terms_and_conditions = 'terms_and_conditions',
}

export const COMPANY_AGREEMENT_MAP = {
  [COMPANY_AGREEMENT.service_summary]: {
    link: 'https://storage.googleapis.com/res-carrick/onboarding/MaverickX%20Service%20Summary.pdf',
    title: 'Service Summary',
  },
  [COMPANY_AGREEMENT.terms_and_conditions]: {
    link: 'https://storage.googleapis.com/res-carrick/onboarding/MaverickX%20T%26Cs.pdf',
    title: 'Terms and Conditions',
  },
};
