import { ApiService } from 'common/services';
import { BrandsHub } from '../constants/brands-hub';

class RootApiService extends ApiService {
  initWithHash = ({ hash, email, brandsHub }: { hash: string; email: string; brandsHub: BrandsHub }) => {
    return this.post(`/api/v1/onboarding/init?hash=${hash}&email=${email}&brands_hub=${brandsHub}`);
  };

  verifyInviteLink = (payload: string) => {
    return this.post('/api/v1/invite/verify', { code: payload });
  };
}

export const rootApi = new RootApiService(process.env.REACT_APP_API_URL || 'https://register.prospected.ai/api');
