import { Box, InputAdornment, OutlinedInput, Switch, Typography, TextField, SxProps } from '@mui/material';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import { useFormikContext } from 'formik';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import { theme } from '../../../../common/constants/theme';

const styles: Styles = {
  checkboxArea: { display: 'flex', flexDirection: 'row' },
  checkboxBar: { width: '100%', display: 'flex', flexDirection: 'column' },
  fieldRowContainer: { display: 'flex', flexDirection: 'row' },
  fieldContainer: { width: '100%', mt: 2 },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  labelCheck: { fontSize: 12 },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    height: '48px',
    marginBottom: '8px',
    padding: '16px',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '16px',
    lineHeight: '20px',
    minWidth: '200px',
  },
  contact: {
    marginTop: '16px',
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    width: '100%',
  },
  contactSwitch: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 32px)',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingX: '20px',
    height: '48px',
  },
  social: {
    marginTop: '37px',
    fontWeight: 600,
    fontSize: '18px',
  },
  instField: {
    width: '100%',
  },
  brpad: {
    p: '0 5px',
  },
};

const StyledField: SxProps = { width: '100%' };

export function SignatureInformation(): ReactElement {
  const { values, setFieldValue } = useFormikContext<SignUpFormValues>();

  const [isAccountReceivable, setAccountReceivable] = useState<boolean>(true);
  const [isAccountRelated, setAccountRelated] = useState<boolean>(true);

  const handleSetSocialValue = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    setFieldValue(type, e.target.value);
  };

  useEffect(() => {
    if (isAccountReceivable) {
      setFieldValue('arContactFirstName', values.firstName ?? '');
      setFieldValue('arContactLastName', values.lastName ?? '');
      setFieldValue('arContactEmail', values.email ?? '');
    }
    if (isAccountRelated) {
      setFieldValue('acctContactFirstName', values.firstName ?? '');
      setFieldValue('acctContactLastName', values.lastName ?? '');
      setFieldValue('acctContactEmail', values.email ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountReceivable) {
      setFieldValue('arContactFirstName', values.firstName ?? '');
      setFieldValue('arContactLastName', values.lastName ?? '');
      setFieldValue('arContactEmail', values.email ?? '');
    } else {
      setFieldValue('arContactFirstName', '');
      setFieldValue('arContactLastName', '');
      setFieldValue('arContactEmail', '');
    }
    if (isAccountRelated) {
      setFieldValue('acctContactFirstName', values.firstName ?? '');
      setFieldValue('acctContactLastName', values.lastName ?? '');
      setFieldValue('acctContactEmail', values.email ?? '');
    } else {
      setFieldValue('acctContactFirstName', '');
      setFieldValue('acctContactLastName', '');
      setFieldValue('acctContactEmail', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountReceivable, isAccountRelated]);

  return (
    <>
      <Box sx={styles.contact}>
        <Typography sx={styles.labelCheck}>Accounts Receivable Contact</Typography>
        <Box sx={styles.contactSwitch}>
          <Typography sx={styles.title}>Same as entered information</Typography>
          <Switch checked={isAccountReceivable} onClick={() => setAccountReceivable(!isAccountReceivable)} />
        </Box>
      </Box>
      {!isAccountReceivable && (
        <>
          <Box sx={styles.fieldRowContainer}>
            <Box sx={styles.fieldContainer}>
              <Typography sx={styles.label}>Account Receivable First Name</Typography>
              <Box sx={styles.inputContainer}>
                <Field component={TextField} name="arContactFirstName" sx={{ ...StyledField }} />
              </Box>
            </Box>
            <Box sx={styles.brpad} />
            <Box sx={styles.fieldContainer}>
              <Typography sx={styles.label}>Account Receivable Last Name</Typography>
              <Box sx={styles.inputContainer}>
                <Field component={TextField} name="arContactLastName" sx={{ ...StyledField }} />
              </Box>
            </Box>
          </Box>
          <Box sx={styles.fieldContainer}>
            <Typography sx={styles.label}>Account Receivable Email</Typography>
            <Box sx={styles.inputContainer}>
              <Field component={TextField} name="arContactEmail" sx={{ ...StyledField }} />
            </Box>
          </Box>
        </>
      )}
      <Box sx={styles.contact}>
        <Typography sx={styles.labelCheck}>Account Performance Contact</Typography>
        <Box sx={styles.contactSwitch}>
          <Typography sx={styles.title}>Same as entered information</Typography>
          <Switch checked={isAccountRelated} onClick={() => setAccountRelated(!isAccountRelated)} />
        </Box>
      </Box>
      {!isAccountRelated && (
        <>
          <Box sx={styles.fieldRowContainer}>
            <Box sx={styles.fieldContainer}>
              <Typography sx={styles.label}>Account Contact First Name</Typography>
              <Box sx={styles.inputContainer}>
                <Field component={TextField} name="acctContactFirstName" sx={{ ...StyledField }} />
              </Box>
            </Box>
            <Box sx={styles.brpad} />
            <Box sx={styles.fieldContainer}>
              <Typography sx={styles.label}>Account Contact Last Name</Typography>
              <Box sx={styles.inputContainer}>
                <Field component={TextField} name="acctContactLastName" sx={{ ...StyledField }} />
              </Box>
            </Box>
          </Box>
          <Box sx={styles.fieldContainer}>
            <Typography sx={styles.label}>Account Contact Email</Typography>
            <Box sx={styles.inputContainer}>
              <Field component={TextField} name="acctContactEmail" sx={{ ...StyledField }} />
            </Box>
          </Box>
        </>
      )}
      <Typography sx={styles.social}>Social</Typography>
      <Box sx={styles.fieldRowContainer}>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Facebook</Typography>
          <Box sx={styles.inputContainer}>
            <OutlinedInput
              value={values.facebook}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetSocialValue(e, 'facebook')}
              endAdornment={
                <InputAdornment position="end">
                  <img alt="facebook-icon" src="assets/images/facebook.svg" />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box sx={styles.brpad} />
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Tiktok</Typography>
          <Box sx={styles.inputContainer}>
            <OutlinedInput
              value={values.tiktok}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetSocialValue(e, 'tiktok')}
              endAdornment={
                <InputAdornment position="end">
                  <img alt="tiktok-icon" src="assets/images/tiktok.svg" />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.fieldRowContainer}>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Twitter</Typography>
          <Box sx={styles.inputContainer}>
            <OutlinedInput
              value={values.twitter}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetSocialValue(e, 'twitter')}
              endAdornment={
                <InputAdornment position="end">
                  <img alt="twitter-icon" src="assets/images/twitter.svg" />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box sx={styles.brpad} />
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.label}>Youtube</Typography>
          <Box sx={styles.inputContainer}>
            <OutlinedInput
              value={values.youtube}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetSocialValue(e, 'youtube')}
              endAdornment={
                <InputAdornment position="end">
                  <img alt="youtube-icon" src="assets/images/youtube.svg" />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Instagram</Typography>
        <Box sx={styles.inputContainer}>
          <OutlinedInput
            sx={styles.instField}
            value={values.instagram}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetSocialValue(e, 'instagram')}
            endAdornment={
              <InputAdornment position="end">
                <InstagramIcon />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
    </>
  );
}
