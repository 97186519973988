const SIGN_UP_MODULE = '[sign-up:info]';

const POST_ASIN_INFORMATION = `${SIGN_UP_MODULE} POST_ASIN_INFORMATION`;
const POST_BUSINESS_INFORMATION = `${SIGN_UP_MODULE} POST_BUSINESS_INFORMATION`;
const POST_ADDITIONAL_INFORMATION = `${SIGN_UP_MODULE} POST_ADDITIONAL_INFORMATION`;
const POST_CONTRACT_TERMS = `${SIGN_UP_MODULE} POST_CONTRACT_TERMS`;
const POST_PAYMENT_INFORMATION = `${SIGN_UP_MODULE} POST_PAYMENT_INFORMATION`;
const GET_WALLET = `${SIGN_UP_MODULE} GET_WALLET`;
const CREATE_WALLET = `${SIGN_UP_MODULE} CREATE_WALLET`;

const ADD_USER_ID = `${SIGN_UP_MODULE} ADD_USER_ID`;
const ADD_SIGN_URL = `${SIGN_UP_MODULE} ADD_SIGN_URL`;

const ADD_CARD_DATA = `${SIGN_UP_MODULE} ADD_CARD_DATA`;

const GET_USER_INFO = `${SIGN_UP_MODULE} GET_USER_INFO`;
const GET_USER_INFO_SUCCESS = `${SIGN_UP_MODULE} GET_USER_INFO_SUCCESS`;
const GET_USER_INFO_ERROR = `${SIGN_UP_MODULE} GET_USER_ERROR`;

const SET_USER_INFO = `${SIGN_UP_MODULE} SET_USER_INFO`;

const COMPLETE_SIGN_UP = `${SIGN_UP_MODULE} COMPLETE_SIGN_UP`;

export const signUpActionTypes = {
  POST_ASIN_INFORMATION,
  POST_BUSINESS_INFORMATION,
  POST_ADDITIONAL_INFORMATION,
  POST_CONTRACT_TERMS,
  POST_PAYMENT_INFORMATION,
  ADD_USER_ID,
  ADD_SIGN_URL,
  ADD_CARD_DATA,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  SET_USER_INFO,
  GET_WALLET,
  CREATE_WALLET,
  COMPLETE_SIGN_UP,
};
