import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'root/layout';
import { getRoutes } from 'common/constants/routes';
import { useQuery } from 'common/hooks';
import { putLanguage } from 'common/store/actions';
import { selectRootLanguage } from 'common/store/selectors';
import { Helmet } from 'react-helmet';
import { isIgnite } from '../../common/constants/theme';

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const routes = getRoutes();
  const lng = useQuery('lng');

  const language = useSelector(selectRootLanguage);

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    dispatch(putLanguage(lng));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense>
      <Helmet>
        <meta
          name="description"
          content={`From click to sale, ${
            isIgnite() ? 'Ignite' : 'MaverickX'
          } is data-driven ad analytics with best-in-class tech that tracks and optimizes your ad campaigns to maximize revenue and growth.`}
        />
        <link rel="icon" href={isIgnite() ? '/favicons/favicon-2.png' : '/favicons/favicon-1.ico'} />
        <title>{isIgnite() ? 'Ignite' : 'MaverickX'} | It’s Not The Plane, It’s The Pilot</title>
      </Helmet>
      <Layout>
        <Routes>
          {routes.map(route => (
            <Route path={route.path} key={route.path} element={<route.component />} />
          ))}
        </Routes>
      </Layout>
    </Suspense>
  );
}
