import { ApiService } from 'common/services';

class TeamInviteApiService extends ApiService {
  verifyInvationCode = (payload: string) => {
    return this.post('/api/v1/team/invitation/verify', {
      code: payload,
    });
  };

  verifyInvitationAdminsCode = (payload: string) => {
    return this.post('/api/v1/admins/invitation/verify', {
      code: payload,
    });
  };

  updateInvitationUser = (payload: TeamInviteModule.TeamInviteInformation) => {
    return this.post('/api/v1/team/invitation/update', payload);
  };

  updateInvitationAdmin = (payload: TeamInviteModule.TeamInviteInformation) => {
    return this.post('/api/v1/admins/invitation/update', payload);
  };
}

export const teamInviteApi = new TeamInviteApiService(
  process.env.REACT_APP_API_URL || 'https://register.prospected.ai/api'
);
