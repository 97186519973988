import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root-store';
import { teamInviteApi } from 'modules/team-invite/service/team-invite.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { teamInviteActions, teamInviteActionTypes as T } from 'modules/team-invite/store/actions';

function* verifyInvationCode({ payload }: Action) {
  try {
    if (payload.path === '/invite-user/') {
      const { data } = yield call(teamInviteApi.verifyInvationCode, payload.code);
      yield put(teamInviteActions.verifyInvationCodeSuccess(data.exist));
    } else {
      const { data } = yield call(teamInviteApi.verifyInvitationAdminsCode, payload.code);
      yield put(teamInviteActions.verifyInvationCodeSuccess(data.exist));
    }
  } catch (error) {
    yield put(teamInviteActions.verifyInvationCodeError(error as Error));
  }
}

function* updateInvitationUser({ payload, meta }: Action) {
  try {
    const { path, ...newPayload } = payload;
    if (path === '/invite-user/') {
      const { data } = yield call(teamInviteApi.updateInvitationUser, newPayload);
      yield put(teamInviteActions.updateInvitationUserSuccess());
      meta.onSuccess(data.url);
    } else {
      const { data } = yield call(teamInviteApi.updateInvitationAdmin, newPayload);
      yield put(teamInviteActions.updateInvitationUserSuccess());
      meta.onSuccess(data.url);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(teamInviteActions.updateInvitationUserError(error as Error));
    meta.onError(error.response.data.message as string);
  }
}

export function* teamInviteSaga(): SagaIterator {
  yield all([
    takeLatest(T.VERIFY_INVITATION_CODE, verifyInvationCode),
    takeLatest(T.UPDATE_INVITATION_USER, updateInvitationUser),
  ]);
}
