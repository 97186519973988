import { quickStartActionTypes as T } from './action-types';

const postQuickStartForm = (payload: QuickStartTypes.Form) => ({
  type: T.POST_QUICK_START_FORM,
  payload,
});

const setQuickStartStep = (payload: string) => ({
  type: T.SET_QUICK_START_STEP,
  payload,
});

const setQuickUserData = (payload: string) => ({
  type: T.SET_QUICK_USER_DATA,
  payload,
});

const postQuickLoginForm = (payload: QuickStartTypes.User) => ({
  type: T.POST_QUICK_LOGIN_FORM,
  payload,
});

const postQuickSignUpForm = (payload: QuickStartTypes.User) => ({
  type: T.POST_QUICK_SIGNUP_FORM,
  payload,
});

export const quickStartActions = {
  postQuickStartForm,
  setQuickStartStep,
  postQuickLoginForm,
  postQuickSignUpForm,
  setQuickUserData,
};
