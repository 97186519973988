import { Styles } from 'common/types';

export const getStyles = (): Styles => ({
  login: {
    fontFamily: 'Roboto',
    width: '100%',
    color: '#060F14',
    fontSize: '16px',
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginBtn: {
    marginLeft: '5px',
    fontFamily: 'Roboto',
    color: '#4288F0',
    cursor: 'pointer',
  },
});
