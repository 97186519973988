import { State } from 'common/store/reducers';

export const selectUserId = (state: State) => state.signUp.signUp.user_id;

export const selectSignUrl = (state: State) => state.signUp.signUp.url_sign;

export const selectCards = (state: State) => state.signUp.signUp.cards;

export const selectLoadingUrl = (state: State) => state.signUp.signUp.loading_url;

export const selectUserInfo = (state: State) => state.signUp.signUp.user_info;
