import { Action, UserData } from 'common/interfaces/root-store';
import {
  INIT_WITH_HASH,
  INIT_WITH_HASH_ERROR,
  INIT_WITH_HASH_SUCCESS,
  PUT_LANGUAGE,
  VERIFY_INVITE_LINK,
  SIGN_UP_COMPLETE,
  INIT_REMOVE_LOADING,
} from './actionsTypes';
import { BrandsHub } from '../../../constants/brands-hub';

export const putLanguage = (lang: string): Action => ({
  type: PUT_LANGUAGE,
  payload: lang,
});

export const initWithHash = (
  hash: string,
  email: string,
  brandsHub: BrandsHub,
  onSuccess: () => void,
  onError: (errorMessage: string) => void
): Action => ({
  type: INIT_WITH_HASH,
  payload: { hash, email, brandsHub },
  meta: { onSuccess, onError },
});

export const initWithHashSuccess = (payload: UserData): Action => ({
  type: INIT_WITH_HASH_SUCCESS,
  payload,
});

export const initWithHashError = (): Action => ({
  type: INIT_WITH_HASH_ERROR,
});

export const initRemoveLoading = (): Action => ({
  type: INIT_REMOVE_LOADING,
});

export const verifyInviteLink = (payload: string, onGenerated: () => void): Action => ({
  type: VERIFY_INVITE_LINK,
  payload,
  meta: { onGenerated },
});

export const setCompleteSignUp = (payload: string): Action => ({
  type: SIGN_UP_COMPLETE,
  payload,
});
