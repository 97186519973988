import { useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ProgressBar } from 'common/ui';
import { SIGN_UP_STEP, STEP_TITLE_MAP } from 'modules/sign-up/constants';
import { BusinessInformation } from 'modules/sign-up/components/business-information';
import { useWindowSize } from 'common/hooks';
import { breakpointsNumber } from 'common/constants/breakpoints';
import { BackgroundImages } from 'common/ui/background-images';
import { SignUpFormValues } from 'modules/sign-up/sign-up';
import { signUpActions } from 'modules/sign-up/store/actions';
import { selectCards } from 'modules/sign-up/store/selectors';
import { BrandsHub } from 'common/constants/brands-hub';
import { AsinInformation } from '../asin-information';
import { ContractTerms } from '../contract-terms';
import { SignatureInformation } from '../signature-information';
import { PaymentInformation } from '../payment-information';
import { ButtonWrappers, getSignUpStyles, Wrapper } from './sign-up-content-styles';
import { isIgnite, theme } from '../../../../common/constants/theme';
import { getBrandsHub, getLoginUrl } from '../../helpers';

export function SignUpContent(): ReactElement {
  const dispatch = useDispatch();
  const { errors, getFieldMeta, setFieldTouched, values } = useFormikContext<SignUpFormValues>();
  const { width } = useWindowSize();

  const cards = useSelector(selectCards);

  const [currentStep, setCurrentStep] = useState<SIGN_UP_STEP>(SIGN_UP_STEP.ASIN_INFORMATION);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completedSteps, setCompletedSteps] = useState<Array<SIGN_UP_STEP>>([]);
  const [displayBottomMenu, setDisplayBottomMenu] = useState<boolean>(false);
  const [isGeneratingToken, setIsGeneratingToken] = useState<boolean>(false);
  const [isLoadingStep, setIsLoadingStep] = useState<boolean>(true);
  const [isProceedingStep, setIsProceedingStep] = useState<boolean>(false);

  const enableFourthStep =
    (currentStep === SIGN_UP_STEP.CONTRACT_TERMS && values.summaryService && values.termsAndConditionals) ||
    currentStep !== SIGN_UP_STEP.CONTRACT_TERMS;

  const topAnchorRef = useRef<HTMLDivElement>();
  const nextRef = useRef<HTMLButtonElement>(null);

  const hash = sessionStorage.getItem('hash');
  const isTablet = width <= breakpointsNumber.tablet;
  const isFirstStep = currentStep === 1;

  const styles = getSignUpStyles({ isTablet, isFirstStep });

  const handleBackStep = () => {
    setDisplayBottomMenu(false);
    setCurrentStep(prev => prev - 1);
  };

  const handleOpenLogin = () => {
    const url = getLoginUrl();
    window.open(url, '_blank');
  };

  const getValidationFieldsByStep = useCallback(() => {
    switch (currentStep) {
      case SIGN_UP_STEP.BUSINESS_INFORMATION:
        return ['firstName', 'lastName', 'password', 'confirmPassword'];
      case SIGN_UP_STEP.ASIN_INFORMATION:
        return ['companyName', 'domain', 'address'];
      case SIGN_UP_STEP.PAYMENT_INFORMATION:
        return ['orders'];
      default:
        return [];
    }
  }, [currentStep]);

  const handleStepSuccess = () => {
    setIsProceedingStep(false);
    setCurrentStep(prev => prev + 1);
    setCompletedSteps(prev => [...prev, currentStep]);
  };

  const handleStepError = (value: string) => {
    setIsProceedingStep(false);
    toast.error(value);
  };

  const submitForm = () => {
    dispatch(signUpActions.completeSingUp({ hash }, handleStepError));
  };

  const handleWalletCreation = () => {
    setIsGeneratingToken(true);
  };

  const handleNextStep = () => {
    setDisplayBottomMenu(false);
    setIsProceedingStep(true);
    const foundErrors = Object.entries(errors).filter(
      entry => getValidationFieldsByStep().includes(entry[0]) && getFieldMeta(entry[0]).error
    );

    if (foundErrors.length) {
      setIsProceedingStep(false);
      toast.error('Enter required fields...');
      foundErrors.forEach(entry => setFieldTouched(entry[0]));
      return;
    }

    if (currentStep === SIGN_UP_STEP.BUSINESS_INFORMATION) {
      const data = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone,
        password: values.password,
        step: 'user_information',
      };
      dispatch(signUpActions.postBusinessInformation({ data, hash }, handleStepSuccess, handleStepError));
    }

    if (currentStep === SIGN_UP_STEP.ASIN_INFORMATION) {
      const data = {
        company_name: values.companyName,
        domain: values.domain,
        is_affiliate_content: values.isAffiliateContent,
        is_brand: values.isBrand,
        address: values.address,
        step: 'company_information',
      };
      dispatch(signUpActions.postAsinInformation({ data, hash }, handleStepSuccess, handleStepError));
    }

    if (currentStep === SIGN_UP_STEP.ADDITIONAL_INFORMATION) {
      const data = {
        ar_contact_first_name: values.arContactFirstName,
        ar_contact_last_name: values.arContactLastName,
        ar_contact_email: values.arContactEmail,

        acct_contact_first_name: values.acctContactFirstName,
        acct_contact_last_name: values.acctContactLastName,
        acct_contact_email: values.acctContactEmail,

        socials: {
          facebook: values.facebook !== '' ? values.facebook : null,
          instagram: values.instagram !== '' ? values.instagram : null,
          tiktok: values.tiktok !== '' ? values.tiktok : null,
          twitter: values.twitter !== '' ? values.twitter : null,
          youtube: values.youtube !== '' ? values.youtube : null,
        },

        step: 'additional_information',
      };
      dispatch(signUpActions.postAdditionalInformation({ data, hash }, handleStepSuccess, handleStepError));
    }

    if (currentStep === SIGN_UP_STEP.CONTRACT_TERMS) {
      dispatch(
        signUpActions.postContractTerms(
          { summary_service: values.summaryService, terms_and_conditionals: values.termsAndConditionals, hash },
          handleStepSuccess,
          handleStepError
        )
      );
    }

    if (currentStep === SIGN_UP_STEP.PAYMENT_INFORMATION) {
      if (!cards || cards?.length === 0) {
        setIsProceedingStep(false);
        return;
      }
      const data = {
        card_id: cards.pop().id,
        orders: values.orders,
      };

      dispatch(signUpActions.completeSingUp({ hash, data }, handleStepError));
    }
  };

  useEffect(() => {
    if (currentStep === SIGN_UP_STEP.PAYMENT_INFORMATION && cards) {
      handleNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  const createWallet = (stripeCardToken: string) => {
    setIsProceedingStep(true);
    dispatch(signUpActions.createWallet({ hash, card_token_id: stripeCardToken }, setIsProceedingStep));
  };

  const handleSetDefaultStep = () => {
    if (values.companyName && values.domain && values.address) {
      setCurrentStep(SIGN_UP_STEP.BUSINESS_INFORMATION);
    }
    if (values.firstName && values.lastName && values.companyName && values.domain && values.address) {
      setCurrentStep(SIGN_UP_STEP.ADDITIONAL_INFORMATION);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case SIGN_UP_STEP.BUSINESS_INFORMATION:
        return <BusinessInformation />;

      case SIGN_UP_STEP.ASIN_INFORMATION:
        return <AsinInformation />;

      case SIGN_UP_STEP.ADDITIONAL_INFORMATION:
        return <SignatureInformation />;

      case SIGN_UP_STEP.CONTRACT_TERMS:
        return <ContractTerms />;

      case SIGN_UP_STEP.PAYMENT_INFORMATION:
        return (
          <PaymentInformation
            isGeneratingToken={isGeneratingToken}
            setIsGeneratingToken={setIsGeneratingToken}
            createWallet={createWallet}
          />
        );

      default:
        return <AsinInformation />;
    }
  };

  useEffect(() => {
    handleSetDefaultStep();
    setIsLoadingStep(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, currentStep]);

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (nextRef?.current?.offsetTop > window.pageYOffset + window.innerHeight) {
        setTimeout(() => {
          setDisplayBottomMenu(true);
        });
      } else {
        setTimeout(() => {
          setDisplayBottomMenu(false);
        });
      }
    });
  }, [nextRef, currentStep]);

  return (
    <>
      {!isLoadingStep ? (
        <Box sx={styles.wrapper} ref={topAnchorRef}>
          <Box sx={styles.wrapperForm}>
            <Box sx={styles.header}>
              <Box sx={styles.stepWrapper}>
                {!isTablet && <Typography>You are here:</Typography>}
                <Typography sx={styles.stepName}> {STEP_TITLE_MAP[currentStep]?.name} </Typography>
              </Box>
            </Box>
            <Box sx={styles.progressBarWrapper}>
              <ProgressBar
                color={theme.palette.primary.main}
                stepsCount={!values.isBrand || (values.isBrand && isIgnite()) ? 4 : 5}
                currentStep={STEP_TITLE_MAP[currentStep]?.step}
              />
            </Box>
            <Box sx={styles.content}>
              <Typography sx={styles.stepTitle}>
                Step {STEP_TITLE_MAP[currentStep]?.step} to {!values.isBrand || (values.isBrand && isIgnite()) ? 4 : 5}
              </Typography>
              <Typography variant="h4" sx={styles.title}>
                {STEP_TITLE_MAP[currentStep]?.title}
              </Typography>
              {currentStep === SIGN_UP_STEP.ASIN_INFORMATION && (
                <Typography sx={styles.loginWrapper}>
                  Already have an account?{' '}
                  <Typography sx={styles.loginLink} onClick={handleOpenLogin}>
                    Log In
                  </Typography>
                </Typography>
              )}
              <Box sx={styles.formContent}>{renderCurrentStep()}</Box>
              <Box sx={styles.bttnWrapper}>
                {currentStep !== SIGN_UP_STEP.ASIN_INFORMATION && (
                  <Button variant="contained" sx={styles.saveBttn} onClick={handleBackStep}>
                    Back
                  </Button>
                )}
                {((currentStep !== SIGN_UP_STEP.PAYMENT_INFORMATION &&
                  values.isBrand &&
                  getBrandsHub() === BrandsHub.MaverickX) ||
                  (currentStep !== SIGN_UP_STEP.CONTRACT_TERMS && !values.isBrand) ||
                  (currentStep !== SIGN_UP_STEP.PAYMENT_INFORMATION &&
                    currentStep !== SIGN_UP_STEP.CONTRACT_TERMS &&
                    values.isBrand &&
                    getBrandsHub() === BrandsHub.Ignite)) && (
                  <Button
                    type="button"
                    sx={styles.nextStepBttn}
                    disabled={!enableFourthStep}
                    onClick={handleNextStep}
                    ref={nextRef}
                    variant="contained"
                  >
                    {isProceedingStep ? <CircularProgress size={26} color="inherit" /> : 'Next Step'}
                  </Button>
                )}
                {(currentStep === SIGN_UP_STEP.PAYMENT_INFORMATION ||
                  (currentStep === SIGN_UP_STEP.CONTRACT_TERMS && !values.isBrand)) && (
                  <Button sx={styles.nextStepBttn} onClick={values.isBrand ? handleWalletCreation : submitForm}>
                    {isProceedingStep ? <CircularProgress size={26} color="inherit" /> : 'Finish'}
                  </Button>
                )}
                {currentStep === SIGN_UP_STEP.CONTRACT_TERMS &&
                  values.isBrand &&
                  getBrandsHub() === BrandsHub.Ignite && (
                    <Button sx={styles.nextStepBttn} onClick={submitForm} disabled={!enableFourthStep}>
                      {isProceedingStep ? <CircularProgress size={26} color="inherit" /> : 'Finish'}
                    </Button>
                  )}
              </Box>
            </Box>
          </Box>
          <BackgroundImages />
        </Box>
      ) : (
        <Wrapper>
          <CircularProgress color="primary" />
        </Wrapper>
      )}
      {displayBottomMenu && (
        <ButtonWrappers>
          <Box
            sx={
              currentStep !== 1
                ? styles.buttonPanelContent
                : { ...styles.buttonPanelContent, justifyContent: 'flex-end' }
            }
          >
            {currentStep !== SIGN_UP_STEP.ASIN_INFORMATION && (
              <Typography sx={styles.backStep} onClick={handleBackStep}>{`< ${
                STEP_TITLE_MAP[((currentStep as number) - 1) as SIGN_UP_STEP]?.name
              }`}</Typography>
            )}
            <Button variant="contained" type="button" sx={styles.nextStepBttn} onClick={handleNextStep}>
              Next Step
            </Button>
          </Box>
        </ButtonWrappers>
      )}
    </>
  );
}
