import { ApiService } from 'common/services';
import { IAsinData } from '../components/payment-information/payment-information';

class SignUpApiService extends ApiService {
  getUserInfo = (payload: string) => {
    return this.get(`/api/v1/onboarding/?hash=${payload}`);
  };

  postCompanyInformation = (payload: { data: SignUpModule.CompanyInformation; hash: string }) => {
    return this.post(`/api/v1/onboarding?hash=${payload.hash}`, payload.data);
  };

  postBusinessInformation = (payload: { data: SignUpModule.BusinessInformation; hash: string }) => {
    return this.post(`/api/v1/onboarding?hash=${payload.hash}`, payload.data);
  };

  postAdditionalInformation = (payload: { data: SignUpModule.AdditionalInformation; hash: string }) => {
    return this.post(`/api/v1/onboarding?hash=${payload.hash}`, payload.data);
  };

  postAgreementInformation = (payload: { summary_service: string; terms_and_conditionals: string; hash: string }) => {
    return this.post(`/api/v1/onboarding?hash=${payload.hash}`, {
      step: 'agreement',
      summary_service: payload.summary_service,
      terms_and_conditionals: payload.terms_and_conditionals,
    });
  };

  postPaymentInformation = (payload: { data: SignUpModule.PaymentInformation; hash: string }) => {
    return this.post(`/api/v1/onboarding?hash=${payload.hash}`, payload.data);
  };

  completeSignUp = (payload: {
    hash: string;
    data?: {
      orders: IAsinData;
    };
  }) => {
    return this.post(`/api/v1/onboarding/complete?hash=${payload.hash}`, payload?.data);
  };

  removeSignUp = (payload: string) => {
    return this.delete(`/api/v1/onboarding?hash=${payload}`);
  };

  generateSign = (payload: string) => {
    return this.post(`/api/v1/onboarding/signature?hash=${payload}`);
  };

  getWallet = (payload: { hash: string }) => {
    return this.get(`/api/v1/onboarding/wallet/cards?hash=${payload.hash}`);
  };

  createWallet = (payload: { hash: string; card_token_id: string }) => {
    return this.post(`/api/v1/onboarding/wallet?hash=${payload.hash}`, { card_token_id: payload.card_token_id });
  };
}

export const signUpApi = new SignUpApiService(process.env.REACT_APP_API_URL || 'https://register.prospected.ai/api');
