const MODULE_NAME = 'root';

export const PUT_LANGUAGE = `[${MODULE_NAME}] PUT_LANGUAGE`;

export const INIT_WITH_HASH = `[${MODULE_NAME}] INIT_WITH_HASH`;
export const INIT_WITH_HASH_SUCCESS = `[${MODULE_NAME}] INIT_WITH_HASH_SUCCESS`;
export const INIT_WITH_HASH_ERROR = `[${MODULE_NAME}] INIT_WITH_HASH_ERROR`;
export const INIT_REMOVE_LOADING = `[${MODULE_NAME}] INIT_REMOVE_LOADING`;

export const VERIFY_INVITE_LINK = `[${MODULE_NAME}] VERIFY_INVITE_LINK`;

export const SIGN_UP_COMPLETE = `[${MODULE_NAME}] SIGN_UP_COMPLETE`;
